import React, { useState, useEffect } from 'react'
import IntlMessages from '@jumbo/utils/IntlMessages'
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer'
import GridContainer from '@jumbo/components/GridContainer'
import Grid from '@material-ui/core/Grid'
import CmtCard from '@coremat/CmtCard'
import CmtCardContent from '@coremat/CmtCard/CmtCardContent'
import makeStyles from '@material-ui/core/styles/makeStyles'
import {
  DataGrid,
  ptBR,
  GridOverlay,
  GridToolbarContainer,
  GridToolbarExport,
} from '@material-ui/data-grid'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'
import axios from 'services/auth/jwt/config'
import { NotificationManager, NotificationContainer } from 'react-notifications'
import Moment from 'moment'

const breadcrumbs = [
  { label: 'Home', link: '/' },
  { label: 'Avaliações', isActive: true },
]

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    height: '100%',
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(8),
    right: theme.spacing(8),
  },
}))

const theme = createMuiTheme(ptBR)

const CustomLoadingOverlay = () => {
  return (
    <GridOverlay>
      <div style={{ position: 'absolute', top: 0, width: '100%' }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  )
}

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  )
}

const columns = [
  { field: 'id', headerName: 'id', width: 30 },
  {
    field: 'chatId',
    headerName: 'chatId',
    width: 150,
    valueGetter: (params) =>
      params.row.conversa ? `${params.row.conversa.chatId || ''}` : '',
  },

  {
    field: 'avaliacao',
    headerName: 'Avaliacao',
    width: 250,
    valueGetter: (params) => params.row.avaliacao,
  },

  {
    field: 'atendente',
    headerName: 'Atendente',
    width: 250,
    valueGetter: (params) =>
      params.row.conversa
        ? params.row.conversa.atendente
          ? params.row.conversa.atendente.nome
          : ''
        : '',
  },
  {
    field: 'origem_atendimento',
    headerName: 'Origem Atendimento',
    width: 250,
    valueGetter: (params) =>
      params.row.conversa ? params.row.conversa.origem : '',
  },
  {
    field: 'tipo_avaliacao',
    headerName: 'Tipo Avaliação',
    width: 250,
    valueGetter: (params) => params.row.tipo_avaliacao,
  },
  {
    field: 'createdAt',
    headerName: 'Dt. inclusão',
    type: 'date',
    width: 150,
    valueGetter: (params) => Moment(params.value).format('DD/MM/YYYY  HH:mm'),
  },
]

const Avaliacoes = () => {
  const classes = useStyles()
  const [loading, setLoading] = useState(true)
  const [rowSelected, setRowSelected] = useState([])
  const [data, setData] = useState([])
  const [openCreateDialog, setOpenCreateDialog] = useState(false)
  const [update, setUpdate] = useState()
  const token = localStorage.getItem('token')
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token

  useEffect(() => {
    axios
      .get('avaliacao')
      .then((succes) => {
        var avaliacoes = succes.data.map((row, index) => ({
          ...row,
          id: index,
        }))
        //console.log(avaliacoes);
        setData(avaliacoes)
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
      })
  }, [update])

  return (
    <PageContainer
      heading={<IntlMessages id="sidebar.atendimento.avaliacoes" />}
      breadcrumbs={breadcrumbs}
    >
      <GridContainer>
        <Grid item xs={12}>
          <CmtCard className={classes.cardRoot}>
            <CmtCardContent>
              <ThemeProvider theme={theme}>
                <DataGrid
                  rows={data}
                  columns={columns}
                  checkboxSelection
                  autoHeight
                  pageSize={15}
                  density="compact"
                  loading={loading}
                  components={{
                    Toolbar: CustomToolbar,
                    LoadingOverlay: CustomLoadingOverlay,
                  }}
                  onSelectionModelChange={({ selectionModel }) =>
                    setRowSelected(selectionModel)
                  }
                />
              </ThemeProvider>
            </CmtCardContent>
          </CmtCard>
        </Grid>
      </GridContainer>
      <NotificationContainer />
    </PageContainer>
  )
}

export default Avaliacoes
