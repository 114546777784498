const isCpfValid = (cpf) => {
  cpf = cpf.replace(/[^\d]/g, '')

  if (cpf.length !== 11) {
    return false
  }

  let sum = 0
  for (let i = 0; i < 9; i++) {
    sum += parseInt(cpf.charAt(i)) * (10 - i)
  }
  let firstDigit = (sum * 10) % 11
  if (firstDigit === 10) {
    firstDigit = 0
  }
  if (parseInt(cpf.charAt(9)) !== firstDigit) {
    return false
  }

  sum = 0
  for (let i = 0; i < 10; i++) {
    sum += parseInt(cpf.charAt(i)) * (11 - i)
  }
  let secondDigit = (sum * 10) % 11
  if (secondDigit === 10) {
    secondDigit = 0
  }
  if (parseInt(cpf.charAt(10)) !== secondDigit) {
    return false
  }

  return true
}

const isCnpjValid = (cnpj) => {
  cnpj = cnpj.replace(/[^\d]/g, '')

  if (cnpj.length !== 14) {
    return false
  }

  let sum = 0
  let weight = 5
  for (let i = 0; i < 12; i++) {
    sum += parseInt(cnpj.charAt(i)) * weight
    weight = weight === 2 ? 9 : weight - 1
  }
  let firstDigit = 11 - (sum % 11)
  if (firstDigit >= 10) {
    firstDigit = 0
  }
  if (parseInt(cnpj.charAt(12)) !== firstDigit) {
    return false
  }

  sum = 0
  weight = 6
  for (let i = 0; i < 13; i++) {
    sum += parseInt(cnpj.charAt(i)) * weight
    weight = weight === 2 ? 9 : weight - 1
  }
  let secondDigit = 11 - (sum % 11)
  if (secondDigit >= 10) {
    secondDigit = 0
  }
  if (parseInt(cnpj.charAt(13)) !== secondDigit) {
    return false
  }

  return true
}

export const validateCpfOrCnpj = (value) => {
  if (value.length === 11) {
    return {
      isValid: isCpfValid(value),
      type: 'cpf',
    }
  }

  return {
    isValid: isCnpjValid(value),
    type: 'cnpj',
  }
}

export const addMaskToField = (value) => {
  value = value.replace(/\D/g, '')

  if (value.length <= 11) {
    value = value.replace(/(\d{3})(\d{0,3})?(\d{0,3})?(\d{0,2})/, function(
      match,
      p1,
      p2,
      p3,
      p4,
    ) {
      if (!p2) return p1
      if (!p3) return `${p1}.${p2}`
      if (!p4) return `${p1}.${p2}.${p3}`
      return `${p1}.${p2}.${p3}-${p4}`
    })
  } else if (value.length === 12) {
    value = value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})/, '$1.$2.$3/$4')
  } else if (value.length === 13) {
    value = value.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{1})/,
      '$1.$2.$3/$4-$5',
    )
  } else if (value.length >= 14) {
    value = value.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2}).*/,
      '$1.$2.$3/$4-$5',
    )
  }
  return value
}
