import React from 'react'
import ReactExport from 'react-export-excel'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

const ExportContacts = ({ children, data }) => {
  const contacts = data.map((item) => {
    item.phoneStrings = item.phones.map((contact) => contact.phone).join(',')
    return item
  })
  return (
    <ExcelFile element={children}>
      <ExcelSheet data={contacts} name="Contacts">
        <ExcelColumn label="Id" value="id" />
        <ExcelColumn label="Nome" value="name" />
        <ExcelColumn label="Email" value="email" />
        <ExcelColumn label="Telefones" value="phoneStrings" />
        <ExcelColumn label="Profissao" value="designation" />
        <ExcelColumn label="Facebook" value="facebook" />
        <ExcelColumn label="Instagram" value="instagram" />
      </ExcelSheet>
    </ExcelFile>
  )
}

export default ExportContacts
