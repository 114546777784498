import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { requiredMessage } from '@jumbo/constants/ErrorMessages'

export const ListingItems = ({
  options,
  value,
  setOption,
  label,
  placeHolder,
  setInputEmpty,
  inputEmpty,
}) => {
  return (
    <Autocomplete
      multiple
      id="tags-standard"
      fullWidth
      options={options}
      value={value}
      getOptionLabel={(option) => option.nome}
      onChange={(event, newValue) => {
        if (newValue?.length > 0) {
          setInputEmpty(false)
        }
        setOption(newValue)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={placeHolder}
          helperText={
            inputEmpty ? (
              <span style={{ color: 'red' }}>{requiredMessage}</span>
            ) : null
          }
          error={inputEmpty}
        />
      )}
    />
  )
}
