import React, { useState, useEffect } from 'react'
import CmtCard from '../../../../@coremat/CmtCard'
import { Box } from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import CmtImage from '../../../../@coremat/CmtImage'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import {
  MdSend,
  MdOutlineMotionPhotosPaused,
  MdCancelScheduleSend
} from 'react-icons/md'
import Tooltip from '@material-ui/core/Tooltip'
import { IoLogoWhatsapp } from 'react-icons/io'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import { BsEyeFill } from 'react-icons/bs'
import CircularProgress from '@material-ui/core/CircularProgress'
import socketIOClient from 'socket.io-client'
import { URL_SOCKET } from 'util/socket'
import { useDispatch } from 'react-redux'
import { setNewLog } from 'redux/actions'

const useStyles = makeStyles(theme => ({
  cardRoot: {
    height: '100%',
    position: 'relative',
    '&:hover $actionHoverRoot': {
      left: -25
    }
  },
  userImgRoot: {
    width: 120,
    height: 120,
    '& img': {
      height: '100%'
    }
  },
  actionRoot: {
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 1,
    height: '100%',
    width: 25,
    color: '#457b9d',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '& > .MuiSvgIcon-root': {
      marginLeft: -15
    }
  },
  actionHoverRoot: {
    backgroundColor: '#457b9d',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: 140,
    height: 140,
    borderRadius: '50%',
    position: 'absolute',
    left: 10,
    top: -9,
    zIndex: 1,
    padding: 12,
    transition: 'all 0.3s ease',
    cursor: 'pointer'
  }
}))

const typeCampanha = {
  WhatsApp: <IoLogoWhatsapp size="20px" color="green" />
}

const UserSummery = ({
  key,
  campanha,
  handleEditar,
  handleDelete,
  handleShowLogs,
  handleSend,
  handleCancel,
  handleResume,
  handlePause,
  setCurrentContact
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  // pode conter 3 tipos - enviando.. , carregando e ver lgos
  const [title, setTitle] = useState(
    campanha.status ? campanha.status : 'Ver logs'
  )

  useEffect(() => {
    if (!campanha?._id) return
    const socket = socketIOClient(URL_SOCKET, {
      query: {
        id: campanha._id,
        origin: 'campanha'
      },
      transports: ['websocket']
    })

    socket.on('connect', () => {
      console.log(`[CLIENT_SOCKET_IO] Connected:`, socket.connected)
    })

    socket.on('campanhaSession', data => {
      setTitle(data.status)
      setCurrentContact(data)
    })

    socket.on('campanhaSessionLog', data => {
      dispatch(setNewLog(data))
    })

    return () => socket.disconnect()
  }, [])

  return (
    <CmtCard className={classes.cardRoot}>
      <Box display="flex" alignItems="center" height={1}>
        <Box className={classes.userImgRoot}>
          <CmtImage src={campanha.image || '/images/marketing.jpg'} />
        </Box>
        <Box ml={{ xs: 5, xl: 8 }}>
          <Typography component="div" variant="h4">
            {campanha.name}{' '}
            {title.includes('Pausado') ? (
              <span style={{ color: '#F1C40F' }}>{`(${title})`}</span>
            ) : (
              ''
            )}
          </Typography>
          <Box component="p">{campanha.departamento.nome}</Box>
          {campanha.schedule ? (
            <Box component="p" style={{ fontSize: 12 }}>
              Agendado para:{' '}
              {new Date(campanha.schedule).toLocaleString('pt-BR')}
            </Box>
          ) : null}

          <Box paddingTop="15px">
            <Tooltip title={campanha.typeCampanha}>
              <IconButton>{typeCampanha[campanha.typeCampanha]}</IconButton>
            </Tooltip>
            <Tooltip title="Editar">
              <IconButton
                disabled={title.includes('Enviando')}
                aria-label="delete"
                onClick={() => {
                  handleEditar(campanha)
                }}
              >
                <EditIcon size="20px" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Ver logs">
              <IconButton
                aria-label="logs"
                onClick={() => {
                  handleShowLogs(campanha)
                }}
              >
                <BsEyeFill size="20px" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Excluir">
              <IconButton
                aria-label="delete"
                onClick={() => {
                  handleDelete(campanha)
                }}
              >
                <DeleteIcon size="20px" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Cancelar">
              <IconButton
                aria-label="cancelarEnvio"
                onClick={() => {
                  handleCancel(campanha)
                }}
              >
                <MdCancelScheduleSend size="20px" />
              </IconButton>
            </Tooltip>

            {title.includes('Enviando') && (
              <Tooltip title={title}>
                <IconButton>
                  <CircularProgress color="primary" size="20px" />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Box>
        <Box
          className={classes.actionRoot}
          onClick={() => {
            if (title.includes('Pausado')) {
              handleResume(campanha)
            } else if (title.includes('Enviando')) {
              handlePause(campanha)
            } else {
              handleSend(campanha)
            }
          }}
        >
          <MoreVertIcon style={{ color: '#457b9d' }} />
          <Box className={classes.actionHoverRoot}>
            {title.includes('Pausado') || title.includes('Ver logs') ? (
              <MdSend size="25px" style={{ color: '#fff' }} />
            ) : (
              <MdOutlineMotionPhotosPaused
                size="25px"
                style={{ color: '#fff' }}
              />
            )}
          </Box>
        </Box>
      </Box>
    </CmtCard>
  )
}

export default UserSummery
