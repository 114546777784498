import React from 'react'
import CmtVertical from '../../../../../@coremat/CmtNavigation/Vertical'
import PerfectScrollbar from 'react-perfect-scrollbar'
import makeStyles from '@material-ui/core/styles/makeStyles'
import IntlMessages from '../../../../utils/IntlMessages'
import { PostAdd } from '@material-ui/icons'
import { ImQrcode } from 'react-icons/im'
import { RiContactsBookFill } from 'react-icons/ri'
import { AiOutlineUsergroupAdd, AiOutlineDashboard } from 'react-icons/ai'
import { MdSend, MdDashboard, MdAssessment } from 'react-icons/md'
import { IoMdContacts } from 'react-icons/io'
import { VscHubot } from 'react-icons/vsc'
import { IoPersonAddOutline } from 'react-icons/io5'
import { ImBriefcase } from 'react-icons/im'
import { CgProfile } from 'react-icons/cg'
import { FaFileInvoiceDollar, FaCloudUploadAlt } from 'react-icons/fa'
import { BsChatQuote } from 'react-icons/bs'
import { FiSettings } from 'react-icons/fi'
import { BiMoviePlay } from 'react-icons/bi'
import { IoLogoWhatsapp } from 'react-icons/io'
import { IoPricetags } from 'react-icons/io5'
import { URL_CHAT, URL_TUTORIAL } from 'util/socket'

const useStyles = makeStyles((theme) => ({
  perfectScrollbarSidebar: {
    height: '100%',
    transition: 'all 0.3s ease',
    '.Cmt-sidebar-fixed &, .Cmt-Drawer-container &': {
      height: 'calc(100% - 167px)',
    },
    '.Cmt-modernLayout &': {
      height: 'calc(100% - 72px)',
    },
    '.Cmt-miniLayout &': {
      height: 'calc(100% - 91px)',
    },
    '.Cmt-miniLayout .Cmt-sidebar-content:hover &': {
      height: 'calc(100% - 167px)',
    },
  },
}))

// collapse

const SideBar = () => {
  const classes = useStyles()

  const navigationMenus = [
    {
      name: <IntlMessages id={'sidebar.main'} />,
      type: 'section',
      children: [
        {
          name: <IntlMessages id={'sidebar.tutorial'} />,
          icon: <BiMoviePlay />,
          type: 'link',
          link: URL_TUTORIAL,
        },
        {
          name: <IntlMessages id={'sidebar.dashboards'} />,
          icon: <MdDashboard />,
          type: 'item',
          link: '/app/dashboards',
        },
        {
          name: <IntlMessages id={'sidebar.lead'} />,
          icon: <IoMdContacts />,
          type: 'item',
          link: '/app/leads',
        },
      ],
    },
    {
      name: <IntlMessages id={'sidebar.atendimento'} />,
      type: 'section',
      children: [
        {
          name: <IntlMessages id={'sidebar.atendimento.chat'} />,
          icon: <BsChatQuote />,
          type: 'link',
          link: URL_CHAT,
        },
        // {
        //   name: <IntlMessages id={"sidebar.atendimento.etiquetas"} />,
        //   icon: <IoPricetags />,
        //   type: "item",
        //   description: 'New',
        //   link: "/app/atendimento/etiquetas"
        // },
        {
          name: <IntlMessages id={'sidebar.atendimento.setor'} />,
          icon: <ImBriefcase />,
          type: 'item',
          link: '/app/atendimento/setor',
        },
        {
          name: <IntlMessages id={'sidebar.atendimento.atendente'} />,
          icon: <IoPersonAddOutline />,
          type: 'item',
          link: '/app/atendimento/atendente',
        },

        {
          name: <IntlMessages id={'sidebar.atendimento.avaliacoes'} />,
          icon: <MdAssessment />,
          type: 'item',
          link: '/app/atendimento/avaliacoes',
        },

        {
          name: <IntlMessages id={'sidebar.whatsapp.contato'} />,
          icon: <RiContactsBookFill />,
          type: 'item',
          link: '/app/atendimento/contatos',
        },
        {
          name: <IntlMessages id={'sidebar.whatsapp.campanha'} />,
          icon: <PostAdd />,
          type: 'item',
          link: '/app/atendimento/campanhas',
        },
        {
          name: <IntlMessages id={'sidebar.configuracoes'} />,
          icon: <FiSettings />,
          type: 'item',
          link: '/app/atendimento/configuracoes',
        },
        /* {
          name: <IntlMessages id={"sidebar.chat"} />,
          icon: <BsFillChatQuoteFill />,
          type: "item",
          link: "/app/atendimento/chatinterno"
        }*/
      ],
    },
    {
      name: <IntlMessages id={'sidebar.whatsapp'} />,
      type: 'section',
      children: [
        {
          name: <IntlMessages id={'sidebar.whatsapp.qrcode'} />,
          icon: <ImQrcode />,
          type: 'item',
          link: '/app/whatsapp/qrcode',
        },
        {
          name: <IntlMessages id={'sidebar.whatsapp.oficial'} />,
          icon: <IoLogoWhatsapp />,
          type: 'item',
          link: '/app/whatsapp/oficial',
        },
      ],
    },
    {
      name: <IntlMessages id={'sidebar.robo'} />,
      type: 'section',
      children: [
        {
          name: <IntlMessages id={'sidebar.robo'} />,
          icon: <VscHubot />,
          type: 'item',
          link: '/app/robo',
        },
      ],
    },
    {
      name: <IntlMessages id={'sidebar.minhaconta'} />,
      type: 'section',
      children: [
        {
          name: <IntlMessages id={'popup.profile'} />,
          icon: <CgProfile />,
          type: 'item',
          link: '/app/profile',
        },

        {
          name: <IntlMessages id={'sidebar.minhaconta.invoice'} />,
          icon: <FaFileInvoiceDollar />,
          type: 'item',
          link: '/app/invoice',
        },
        {
          name: <IntlMessages id={'sidebar.minhaconta.files'} />,
          icon: <FaCloudUploadAlt />,
          type: 'item',
          link: '/app/files',
        },
      ],
    },
  ]

  return (
    <PerfectScrollbar className={classes.perfectScrollbarSidebar}>
      <CmtVertical menuItems={navigationMenus} />
    </PerfectScrollbar>
  )
}

export default SideBar
