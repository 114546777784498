import React, { useState, useEffect } from 'react'
import Dialog from '@material-ui/core/Dialog'
import Box from '@material-ui/core/Box'
import GridContainer from '@jumbo/components/GridContainer'
import Grid from '@material-ui/core/Grid'
import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput'
import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'
import makeStyles from '@material-ui/core/styles/makeStyles'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import { NotificationManager } from 'react-notifications'
import axios from 'services/auth/jwt/config'
import Loader from 'react-loaders'

const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    position: 'relative',
  },
  formControl: {
    margin: theme.spacing(2),
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 18,
      color: theme.palette.common.dark,
    },
  },
}))

const AlterarSenha = ({ open, handleDialog }) => {
  const classes = useStyles()

  const [loader, setLoader] = useState(false)
  const [password, setPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassWord, setConfirmNewPassWord] = useState('')
  const [errorPassword, setErrorPassword] = useState()

  const token = localStorage.getItem('token')
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token

  const handleSubmit = () => {
    setLoader(true)

    axios
      .post('usuario/alterarSenha', {
        newPassword,
        password,
      })
      .then((success) => {
        setLoader(false)
        handleClose()
        NotificationManager.success('Senha atualizada!')
      })
      .catch((err) => {
        setLoader(false)
        handleClose()
        NotificationManager.error(err.response.data.message)
      })
  }

  const handleClose = () => {
    setPassword(null)
    setNewPassword(null)
    setConfirmNewPassWord(null)
    setErrorPassword(null)
    handleDialog()
  }

  const handleChangeConfirmPassword = (value) => {
    setConfirmNewPassWord(value)

    if (value == newPassword) {
      setErrorPassword('')
      return false
    }

    setErrorPassword('As senhas não são iguais!')
    return true
  }

  return (
    <Dialog open={open} onClose={handleClose} className={classes.dialogRoot}>
      <DialogTitle className={classes.dialogTitleRoot}>
        {'Alterar Senha'}
      </DialogTitle>
      <DialogContent dividers>
        <Box
          display="flex"
          flexDirection={{ xs: 'column', md: 'row' }}
          alignItems="center"
          mb={{ xs: 6, md: 5 }}
        >
          <GridContainer>
            <Grid item xs={12}>
              <AppTextInput
                fullWidth
                type="password"
                variant="outlined"
                label="Senha anterior"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value)
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <AppTextInput
                fullWidth
                type="password"
                variant="outlined"
                label="Nova senha"
                value={newPassword}
                onChange={(e) => {
                  setNewPassword(e.target.value)
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <AppTextInput
                fullWidth
                variant="outlined"
                type="password"
                label="Confirme a nova senha"
                value={confirmNewPassWord}
                onChange={(e) => {
                  handleChangeConfirmPassword(e.target.value)
                }}
                helperText={errorPassword}
              />
            </Grid>
          </GridContainer>
        </Box>
        <Box display="flex" justifyContent="flex-end" mb={4}>
          <Button onClick={handleClose}>Cancelar</Button>
          <Box ml={2}>
            {loader ? (
              <Loader type="ball-pulse-sync" />
            ) : (
              <Button
                variant="contained"
                color="primary"
                disabled={
                  !newPassword ||
                  !confirmNewPassWord ||
                  !(newPassword == confirmNewPassWord)
                }
                onClick={handleSubmit}
              >
                Salvar
              </Button>
            )}
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default AlterarSenha

AlterarSenha.prototype = {
  open: PropTypes.bool.isRequired,
  handleDialog: PropTypes.func,
  selectedContact: PropTypes.object,
}

AlterarSenha.defaultProps = {
  selectedContact: null,
}
