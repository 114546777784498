import * as React from 'react'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Moment from 'moment'

export default function ImgMediaCard({ data, setCurrentNotification }) {
  return (
    <Card>
      <CardMedia
        component={data.type}
        alt="image"
        height="140"
        image={data.url}
      />
      <CardContent>
        <Typography gutterBottom variant="h6" component="div">
          {data.title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {data.body}
        </Typography>
        <span
          style={{
            paddingTop: '10px',
            fontSize: '10px',
            color: '#a9b6b6',
          }}
        >
          🕓 {Moment(data.createdAt).format('DD/MM/YYYY  HH:mm')}
        </span>
      </CardContent>
      <CardActions>
        <Button size="small" onClick={() => setCurrentNotification(data)}>
          + Informações
        </Button>
      </CardActions>
    </Card>
  )
}
