import React from 'react'
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer'
import IntlMessages from '@jumbo/utils/IntlMessages'
import { Grid } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import CmtCard from '@coremat/CmtCard'
import CmtCardContent from '@coremat/CmtCard/CmtCardContent'
import Alert from '@material-ui/lab/Alert'
import UserDetail from './UserDetail'

import makeStyles from '@material-ui/core/styles/makeStyles'
import { fade, Typography } from '@material-ui/core'
import { blue, pink } from '@material-ui/core/colors'

const breadcrumbs = [
  { label: 'Home', link: '/' },
  { label: 'WhatsApp' },
  { label: 'QrCode', isActive: true },
]

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    height: '100%',
    marginBottom: 10,
  },
  selectBoxRoot: {
    marginBottom: 6,
    display: 'inline-block',
    '& .MuiInput-underline:before, & .MuiInput-underline:after': {
      display: 'none',
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
    '& .MuiInputBase-root': {
      fontSize: 14,
      color: theme.palette.text.secondary,
    },
  },
  addressTitle: {
    fontWeight: theme.typography.fontWeightRegular,
    [theme.breakpoints.up('lg')]: {
      fontSize: 18,
    },
  },
  socialLink: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: -8,
    marginRight: -8,
  },
  socialLinkCol: {
    paddingLeft: 8,
    paddingRight: 8,
    '& .btn': {
      backgroundColor: fade(theme.palette.primary.main, 0.1),
      color: theme.palette.primary.main,
      padding: 6,
      '& .MuiSvgIcon-root': {
        fontSize: 20,
      },
      '&.twitter': {
        backgroundColor: fade(blue[500], 0.1),
        color: blue[500],
      },
      '&.instagram': {
        backgroundColor: fade(pink[500], 0.1),
        color: pink[500],
      },
      '&.linkedin': {
        backgroundColor: fade(blue[500], 0.1),
        color: blue[500],
      },
    },
  },
  contactRoot: {
    '& .MuiSvgIcon-root': {
      fontSize: 20,
    },
  },
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}))

const QrCode = () => {
  const classes = useStyles()

  return (
    <PageContainer
      heading={<IntlMessages id="pages.whatsapp.qrCode" />}
      breadcrumbs={breadcrumbs}
    >
      <Grid item xs={12}>
        <CmtCard className={classes.cardRoot}>
          <CmtCardContent>
            <p style={{ textAlign: 'center' }}>
              <h1>INSTRUÇÕES</h1>
              <br />
              <h4>
                {' '}
                <b>1.</b> Abra o WhatsApp no celular
              </h4>{' '}
              <br />
              <h4>
                {' '}
                <b>2.</b> Toque em Mais opções ou Configurações e selecione
                Aparelhos conectados.
              </h4>
              <br />
              <h4>
                {' '}
                <b>3.</b> Clique em "Conectar um Aparelho" e Aponte seu celular
                para essa tela para capturar o código
              </h4>
            </p>
            <Box className={classes.root} marginTop="15px" marginBottom="15px">
              <Alert severity="info">
                Atualmente, a API oficial do WhatsApp for Business está limitada
                a um restrito grupo de médias e grandes empresas.
                <br />
                Diante deste cenário, os desenvolvedores utilizam o Whatsapp -
                Web para ter acesso a todas as funcionalidades, sem a
                necessidade de aprovação da API oficial.
              </Alert>
              <Alert severity="warning">
                Não é possível realizar a leitura do QrCode para mais de um
                departamento utilizando o mesmo número.
              </Alert>
            </Box>
            <Grid item xs={12} marginTop="15px">
              <UserDetail />
            </Grid>
          </CmtCardContent>
        </CmtCard>
      </Grid>
    </PageContainer>
  )
}

export default QrCode
