import React, { useState } from 'react'
import { Box, Typography } from '@material-ui/core'
import useStyles from '../Atendimento/Contatos/index.style' // "./index.style";
import CmtSearch from '@coremat/CmtSearch'
import { setFilterType } from 'redux/actions/ContactApp'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

const AppHeader = ({ filter, setFilter, handleFind }) => {
  const classes = useStyles()

  return (
    <Box className={classes.inBuildAppHeader}>
      <Box className={classes.inBuildAppHeaderSidebar}>
        <Typography
          className={classes.inBuildAppHeaderTitle}
          component="div"
          variant="h1"
        >
          Leads
        </Typography>
      </Box>

      <Box className={classes.inBuildAppHeaderContent}>
        <CmtSearch
          placeholder="Procurar Contatos..."
          value={filter}
          onKeyPress={(event) => {
            if (event.key.includes('Enter')) {
              handleFind(event)
            }
          }}
          onChange={(event) => setFilter(event.target.value)}
          border={false}
        />
      </Box>
    </Box>
  )
}

export default AppHeader

AppHeader.prototype = {
  onChangeViewMode: PropTypes.func,
}
