import React from 'react'
import { Box, makeStyles, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { PageContainerCounter } from './PageHeaderCounter/pageContainerCounter'

const useStyles = makeStyles((theme) => ({
  pageHeaderRoot: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      alignItems: 'center',
      flexDirection: 'row',
    },
  },
  titleRoot: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: 10,
    },
  },
}))

const PageHeader = ({
  heading,
  breadcrumbComponent,
  children,
  userSummary,
  ...rest
}) => {
  const classes = useStyles()

  return (
    <Box
      className={clsx(classes.pageHeaderRoot, 'page-header')}
      mb={{ xs: 5, md: 6, lg: 8 }}
      {...rest}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Typography
          component="div"
          variant="h1"
          className={clsx(classes.titleRoot, 'title')}
        >
          {heading}
        </Typography>

        <PageContainerCounter items={userSummary} />
      </div>

      <Box
        ml={{ sm: 'auto' }}
        style={{ display: 'flex', alignItems: 'center', gap: '2rem' }}
      >
        {breadcrumbComponent}
      </Box>

      {children}
    </Box>
  )
}

export default PageHeader
