import React from 'react'
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux'
import { Switch } from 'react-router-dom'
import 'react-perfect-scrollbar/dist/css/styles.css'
import configureStore, { history } from './redux/store'
import AppWrapper from './@jumbo/components/AppWrapper'
import AppContextProvider from './@jumbo/components/contextProvider/AppContextProvider'
import Routes from './routes'
import config from './config'
import { Helmet } from 'react-helmet'

export const store = configureStore()

const captlize = (string) => {
  return string.charAt(0).toUpperCase() + string.substr(1)
}

const App = () => (
  <AppContextProvider>
    <Helmet>
      <title> {captlize(config.EMPRESA)} - Admin</title>
    </Helmet>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <AppWrapper>
          <Switch>
            <Routes />
          </Switch>
        </AppWrapper>
      </ConnectedRouter>
    </Provider>
  </AppContextProvider>
)

export default App
