import React, { useEffect, useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import makeStyles from '@material-ui/core/styles/makeStyles'
import CmtCard from '@coremat/CmtCard'
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader'
import CmtCardContent from '@coremat/CmtCard/CmtCardContent'
import CmtList from '@coremat/CmtList'
import RequestItem from './RequestItem'
import ActionSnackBar from './ActionSnackBar'
import axios from 'services/auth/jwt/config'
import PaymentMethod from '../../../Planos/CreditCard'

const useStyles = makeStyles(() => ({
  cardRoot: {
    position: 'relative',
    '& .Cmt-card-content': {
      padding: 0,
      paddingBottom: 24,
    },
  },
  scrollbarRoot: {
    height: 292,
  },
}))

const NewRequests = ({ newCardCredit, setNewCardCredit }) => {
  const classes = useStyles()
  const [requests, setRequests] = useState([])
  const [currentRequest, setCurrentRequest] = useState(null)
  const [openSnackBar, setSnackBarStatus] = useState(false)
  const [snackBarMessage, setSnackBarMessage] = useState('')

  const token = localStorage.getItem('token')
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token

  useEffect(() => {
    axios
      .get('formasPagamentos')
      .then((success) => {
        setRequests(success.data.formasPagamento)
      })
      .catch((err) => err)
  }, [currentRequest])

  const acceptRequest = (request, index) => {
    axios
      .post('formaPagamento/update', {
        newPaymentMethod: request.id,
      })
      .then((success) => {
        setCurrentRequest(index)
        setSnackBarMessage('Cartão definido como padrão com sucesso!')
      })
      .catch((err) => err)
  }

  const rejectRequest = (request, index) => {
    axios
      .post('formaPagamento/delete', {
        paymentMethod: request.id,
      })
      .then((success) => {
        setCurrentRequest(index)
        setSnackBarMessage('Cartão de crédito apagado!')
      })
      .catch((err) => err)
  }

  const handleCloseSnackBar = () => {
    setCurrentRequest(null)
  }

  return (
    <CmtCard className={classes.cardRoot}>
      <CmtCardHeader
        title="Selecionar cartão de crédito"
        subTitle="Defina um cartão de crédito como padrão"
      />
      <CmtCardContent>
        {newCardCredit ? (
          <PaymentMethod
            setCurrentPlano={setNewCardCredit}
            setCurrentRequest={setCurrentRequest}
          />
        ) : (
          <PerfectScrollbar className={classes.scrollbarRoot}>
            <CmtList
              data={requests}
              renderRow={(item, index) => {
                return (
                  <RequestItem
                    key={index}
                    item={item}
                    onAccept={acceptRequest}
                    onReject={rejectRequest}
                    itemIndex={index}
                  />
                )
              }}
            />
          </PerfectScrollbar>
        )}
      </CmtCardContent>

      <ActionSnackBar
        message={snackBarMessage}
        open={openSnackBar}
        onClose={handleCloseSnackBar}
      />
    </CmtCard>
  )
}

export default NewRequests
