import React from 'react'

export const IconsImages = {
  pdf: '/images/files/pdf.png',
  mp3: '/images/files/mp3.png',
  mp4: '/images/files/mp4.png',
  zip: '/images/files/zip.png',
  doc: '/images/files/doc.png',
  docx: '/images/files/doc.png',
  xls: '/images/files/xls.png',
  xlsx: '/images/files/xls.png',
  csv: '/images/files/csv.png',
  ogg: '/images/files/ogg.png',
}
