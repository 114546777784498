import { Box, MenuItem, TextField } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { useState } from 'react'

export const DelayBot = ({ config, setDelayConfig }) => {
  const [delay, setDelay] = useState(config?.delay ? config.delay : 0)
  const [notification, setNotification] = useState(
    config?.notification ? config.notification : 'empty',
  )

  const handleDelay = (e) => {
    let { value } = e.target
    if (value > 99) {
      value = 99
    }
    setDelay(value)
    setDelayConfig({ ...config, delay: value })
  }

  const handleNotification = (e) => {
    const { value } = e.target
    setNotification(value)
    setDelayConfig({ ...config, notification: value })
  }

  return (
    <>
      <Box mb={4}>
        <TextField
          label="Delay"
          type="number"
          variant="standard"
          value={delay}
          onChange={(e) => handleDelay(e)}
          helperText="Tempo do delay em segundos."
        />
      </Box>
      <Box mb={4}>
        <TextField
          label="Digitando ou gravando"
          defaultValue="Nao notificar"
          variant="standard"
          value={notification}
          onChange={(e) => handleNotification(e)}
          select={true}
          style={{ display: 'flex' }}
        >
          <MenuItem value="typing">Digitando</MenuItem>
          <MenuItem value="recording">Gravando</MenuItem>
          <MenuItem value="empty">Não notificar</MenuItem>
        </TextField>
      </Box>
    </>
  )
}
