export const SHOW_MESSAGE = 'show_message'
export const HIDE_MESSAGE = 'hide_message'
export const FETCH_START = 'fetch_start'
export const FETCH_SUCCESS = 'fetch_success'
export const FETCH_ERROR = 'fetch_error'

export const UPDATE_AUTH_USER = 'update_auth_user'
export const UPDATE_LOAD_USER = 'update_load_user'
export const SEND_FORGET_PASSWORD_EMAIL = 'send_forget_password_email'
export const SIGNIN_GOOGLE_USER_SUCCESS = 'signin_google_user_success'
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'signin_facebook_user_success'
export const SIGNIN_TWITTER_USER_SUCCESS = 'signin_twitter_user_success'
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_SUCCESS'
export const SIGNIN_USER_SUCCESS = 'signin_user_success'
export const SIGNOUT_USER_SUCCESS = 'signout_user_success'

export const SET_DASHBOARD_DATA = 'set_dashboard_data'

export const SET_TASK_CURRENT_USER = 'set_task_current_user'
export const SET_TASKS_DATA = 'set_tasks_data'
export const SET_TASK_LIST_DATA = 'set_task_list_data'
export const ADD_TASK = 'add_task'
export const DELETE_TASK = 'delete_task'
export const UPDATE_TASK = 'update_task'
export const SET_FILTER_DATA = 'set_filter_data'
export const ADD_TASK_LIST = 'add_task_list'
export const UPDATE_TASK_LIST = 'update_task_list'
export const DELETE_TASK_LIST = 'delete_task_list'
export const SET_TASK_DETAIL = 'set_task_detail'
export const SEND_MESSAGE = 'send_message'
export const TOGGLE_SIDEBAR_COLLAPSED = 'toggle_sidebar_collapsed'
export const GET_TASKS_COUNTS = 'get_tasks_counts'

//mail app
export const GET_LABELS_LIST = 'get_labels_list'
export const GET_CONNECTIONS_LIST = 'get_connections_list'
export const GET_MAILS_LIST = 'get_mails_list'
export const UPDATE_MAIL_FOLDER = 'update_mail_folder'
export const UPDATE_MAIL_LABEL = 'upade_mail_label'
export const UPDATE_FAVORITE_STATUS = 'update_favorite_status'
export const UPDATE_READ_STATUS = 'update_read_status'
export const UPDATE_IMPORTANT_STATUS = 'update_important_status'
export const COMPOSE_MAIL = 'compose_mail'
export const SET_FILTER_TYPE = 'set_filter_type'
export const GET_SELECTED_MAIL = 'GET_SELECTED_MAIL'
export const UPDATE_SELECTED_MAIL = 'update_selected_mail'
export const NULLIFY_SELECTED_MAIL = 'nullify_selected_mail'
export const REPLY_TO_MAIL = 'reply_to_mail'
export const GET_MAIL_COUNTS = 'get_mail_count'
export const ADD_LABEL = 'add_label'
export const ADD_CONNECTION = 'add_connection'
export const REMOVE_CONNECTION = 'remove_connection'

export const SET_CHAT_USERS = 'set_chat_users'
export const SET_CONTACT_USERS = 'set_contact_users'
export const SET_CURRENT_USER = 'set_current_user'
export const SET_CONVERSATION_DATA = 'set_conversation_data'
export const SEND_NEW_CHAT_MESSAGE = 'send_new_chat_message'
export const SEND_NEW_MEDIA_MESSAGE = 'send_new_media_message'

//Contact App
export const GET_CONTACTS_LIST = 'get_contacts_list'
export const SET_CURRENT_CONTACT = 'set_current_contact'
export const CREATE_CONTACT = 'create_contact'
export const UPDATE_STARRED_STATUS = 'update_starred_status'
export const DELETE_CONTACT = 'delete_contact'
export const UPDATE_CONTACT_LABEL = 'update_contact_label'
export const UPDATE_CONTACT = 'update_contact'
export const GET_CONTACT_COUNTS = 'get_contact_counts'
export const UPDATE_LABEL_ITEM = 'update_label_item'
export const DELETE_LABEL_ITEM = 'delete_label_item'

export const GET_USER_DETAIL = 'get_user_detail'
export const GET_FEED_POSTS = 'get_feed_posts'
export const CREATE_POST = 'create_post'
export const UPDATE_POST = 'update_post'
export const UPDATE = 'get_all_contacts'
export const GET_CONTACT_COUNTS_DUPLICATES = 'get_counts_duplicates'
export const DELETE_CONTACT_COUNTS_DUPLICATES = 'delete_counts_duplicates'
