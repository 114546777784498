import { fetchError, fetchStart, fetchSuccess } from '../../../redux/actions'
import {
  setAuthUser,
  setForgetPassMailSent,
  updateLoadUser,
} from '../../../redux/actions/Auth'
import React from 'react'
import axios from './config'
import config from '../../../config'

const JWTAuth = {
  onRegister: ({ name, email, password, phone, cpf, cnpj }) => {
    return (dispatch) => {
      dispatch(fetchStart())
      axios
        .post('usuario', {
          email: email,
          senha: password,
          nome: name,
          phone: phone,
          ...(!!cpf ? { cpf } : !!cnpj ? { cnpj } : null),
        })
        .then(({ data }) => {
          if (data.user) {
            window.fbq('track', 'PageView')
            window.fbq('track', 'InitiateCheckout')
            dispatch(fetchError('Novo usuário cadastrado com sucesso!'))
            setTimeout(() => {
              window.location.href = '/signin'
            }, 2000)
            // dispatch(JWTAuth.getAuthUser(true, data.user.token));
          } else {
            dispatch(fetchError(data.message))
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error.response.data.message))
        })
        .catch(function(error) {
          dispatch(fetchError('Erro ao tentar realizar o registro!'))
        })
    }
  },

  onLogin: ({ email, password }) => {
    return (dispatch) => {
      try {
        dispatch(fetchStart())
        axios
          .post('auth/login', {
            email: email,
            password: password,
          })
          .then(({ data }) => {
            if (data.user) {
              localStorage.setItem('token', data.user.token)
              axios.defaults.headers.common['Authorization'] =
                'Bearer ' + data.user.token
              dispatch(fetchSuccess())
              dispatch(JWTAuth.getAuthUser(true, data.user.token))
            } else {
              dispatch(fetchError(data.message))
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error.response.data.message))
          })
          .catch((err) => dispatch(fetchError('Erro ao realizar login!')))
      } catch (error) {
        dispatch(fetchError('Erro ao realizar login!'))
      }
    }
  },
  onLogout: () => {
    return (dispatch) => {
      dispatch(fetchStart())
      axios
        .post('auth/logout')
        .then(({ data }) => {
          if (!data.error) {
            dispatch(fetchSuccess())
            localStorage.removeItem('token')
            dispatch(setAuthUser(null))
          } else {
            dispatch(fetchError(data.message))
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error.response.data.message))
        })
    }
  },

  getAuthUser: (loaded = false, token, redirectTo = '') => {
    const queryString = window.location.search

    const params = new URLSearchParams(queryString)

    const tokenQueryParam = params.get('token')
    return (dispatch) => {
      if (!token) {
        const tokenLocal = localStorage.getItem('token')
        axios.defaults.headers.common['Authorization'] =
          'Bearer ' + (tokenLocal || token || tokenQueryParam)
      }
      dispatch(fetchStart())
      dispatch(updateLoadUser(loaded))
      axios
        .post('auth/me')
        .then(({ data }) => {
          if (data.user) {
            dispatch(fetchSuccess())
            dispatch(setAuthUser(data.user))
            if (redirectTo) {
              window.location.href = redirectTo
            }
            try {
              const wehelpCustomerInfo = {
                debug: 1,
                survey_token:
                  'YzhmYmM1NjNmYzc2M2NmMzNiMmRlNTMxMWMyOTE0MWNiZGVlYWM4MDg3MzkzZGQwOGRlMmM2ODU1YTU3YWYzYVa8-V2wyN8wQcFknMgle6-HnxwspSHyVD-bpyFbFTWH',
                type: 'bar',
                message_open: 0,
                language: 'PORTUGUESE',
                company_unit: '1',
                experience_id: null,
                person: {
                  internal_code: data.user._id,
                  name: data.user.nome,
                  email: data.user.email,
                  type: 'CUSTOMER',
                  phone: data.user.phone,
                  // date_of_birth: '2018-07-06',
                  language: 'PORTUGUESE',
                  created_at: data.user.createdAt.split('T')[0],
                  // state: 'RJ',
                  // country: 'BRA',
                  // gender: 'M',
                  document: data.user.cpf || data.user.cnpj,
                  company_unit: '1',
                },
                // custom_fields: [
                //   {
                //     name: 'Nome do campo',
                //     value: 'Valor do campo',
                //   },
                //   {
                //     name: 'Nome do campo',
                //     value: 'Valor do campo',
                //   },
                // ],
              }
              loadWeHelpWidgetScreen(
                'https://app.wehelpsoftware.com',
                wehelpCustomerInfo,
                'root-wehelp',
              )
            } catch {}
          } else {
            dispatch(updateLoadUser(true))
          }
        })
        .catch(function(error) {
          dispatch(updateLoadUser(true))
        })
    }
  },

  onForgotPassword: ({ email }) => {
    return (dispatch) => {
      dispatch(fetchStart())
      axios
        .post('auth/forgot-password', { email })
        .then(() => {
          dispatch(setForgetPassMailSent(true))
          dispatch(fetchSuccess())
        })
        .catch(function(error) {
          dispatch(setForgetPassMailSent(true))
          dispatch(fetchSuccess())
        })
    }
  },
  getSocialMediaIcons: () => {
    return <React.Fragment> </React.Fragment>
  },
}

export default JWTAuth
