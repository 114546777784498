import React, { useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import Pages from './Pages/index'
import Error404 from './Pages/404'
import Login from './Auth/Login'
import Register from './Auth/Register'
import ForgotPasswordPage from './Auth/ForgotPassword'
import { useQuery } from 'hooks/useQuery'
import { fetchSuccess } from 'redux/actions'
import JWTAuth from 'services/auth/jwt'
import axios from 'axios'

const RestrictedRoute = ({ component: Component, ...rest }) => {
  const { authUser } = useSelector(({ auth }) => auth)

  const query = useQuery()
  const isIframe = query.get('iframe') === 'true'
  const token = query.get('token')

  const dispatch = useDispatch()

  useEffect(() => {
    const localToken = localStorage.getItem('token')
    if (localToken && token && localToken === token) {
      return
    }
    if (
      (token && !localToken) ||
      (token && localToken && localToken !== token)
    ) {
      localStorage.setItem('token', token)
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
      dispatch(fetchSuccess())
      dispatch(
        JWTAuth.getAuthUser(
          true,
          token,
          `${rest.location.pathname}?iframe=true&token=${token}`,
        ),
      )
    }
  }, [token])

  return (
    <Route
      {...rest}
      render={(props) =>
        authUser ? (
          !authUser.plano &&
          rest.location.pathname != '/app/planos' &&
          !isIframe ? (
            <Redirect
              to={{
                pathname: `/app/planos`,
                state: { from: props.location },
              }}
            />
          ) : (
            <Component {...props} />
          )
        ) : !isIframe ? (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: props.location },
            }}
          />
        ) : null
      }
    />
  )
}

const Routes = () => {
  const { authUser } = useSelector(({ auth }) => auth)
  const location = useLocation()

  if (location.pathname === '' || location.pathname === '/') {
    return <Redirect to={'/app/dashboards'} />
  } else if (
    authUser &&
    ['/signin', '/signup', '/forgot-password'].includes(location.pathname)
  ) {
    return <Redirect to={'/app/dashboards'} />
  }

  return (
    <React.Fragment>
      <Switch>
        <Route path="/signin" component={Login} />
        <Route path="/signup" component={Register} />
        <Route path="/forgot-password" component={ForgotPasswordPage} />
        <RestrictedRoute path={'/app'} component={Pages} />
        <Route component={Error404} />
      </Switch>
    </React.Fragment>
  )
}

export default Routes
