import React, { useState, useEffect } from 'react'
import IntlMessages from '@jumbo/utils/IntlMessages'
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer'
import GridContainer from '@jumbo/components/GridContainer'
import Grid from '@material-ui/core/Grid'
import CmtCard from '@coremat/CmtCard'
import CmtCardContent from '@coremat/CmtCard/CmtCardContent'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid'
import Button from '@material-ui/core/Button'
import { RiDeleteBin5Fill } from 'react-icons/ri'
import { AiFillEdit } from 'react-icons/ai'
import { IoPersonAddOutline } from 'react-icons/io5'
import CreateLead from './CreateLead'
import axios from 'services/auth/jwt/config'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import Tooltip from '@material-ui/core/Tooltip'
import { NotificationManager, NotificationContainer } from 'react-notifications'
import Moment from 'moment'
import Box from '@material-ui/core/Box'
import Header from './LeadsHeader'
import DownloadIcon from '@mui/icons-material/Download'
import { FileUploadOutlined } from '@mui/icons-material'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useDropzone } from 'react-dropzone'
import { CSVLink } from 'react-csv'
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload'

const breadcrumbs = [
  { label: 'Home', link: '/' },
  { label: 'Leads', isActive: true },
]

const EXAMPLE = {
  data: [
    {
      nome: 'Teste',
      telefone: '558622373217',
      email: 'teste@teste.com',
      origem: 'whatsapp',
    },
  ],
  headers: [
    { label: 'nome', key: 'nome' },
    { label: 'telefone', key: 'telefone' },
    { label: 'email', key: 'email' },
    { label: 'origem', key: 'origem' },
  ],
}

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    height: '100%',
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(8),
    right: theme.spacing(8),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}))

const columns = [
  { field: 'nome', headerName: 'Nome', width: 150 },
  { field: 'email', headerName: 'E-mail', width: 250 },
  { field: 'telefone', headerName: 'Telefone', width: 150 },
  { field: 'origem', headerName: 'Origem', width: 150 },
  { field: 'chatId', headerName: 'chatId', width: 150 },

  {
    field: 'createdAt',
    headerName: 'Dt. inclusão',
    type: 'date',
    width: 150,
    valueGetter: (params) => Moment(params.value).format('DD/MM/YYYY  HH:mm'),
  },
  { field: '_id', headerName: 'id', width: 250 },
]

const Leads = () => {
  const classes = useStyles()
  const [loading, setLoading] = useState(true)
  const [loaderBackdrop, setLoaderBackdrop] = useState(false)
  const [rowSelected, setRowSelected] = useState([])
  const [data, setData] = useState([])
  const [openCreateDialog, setOpenCreateDialog] = useState(false)
  const [update, setUpdate] = useState()
  const [currentLead, setCurrentLead] = useState()
  const [nextPage, setNextPage] = useState(1)
  const [filter, setFilter] = useState('')
  const [rowCounts, setRowCounts] = useState()
  const token = localStorage.getItem('token')
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token

  useEffect(() => {
    axios
      .get(`leads?page=1`)
      .then((succes) => {
        const result_data = succes.data.docs.map((row) => {
          row.id = row._id
          return row
        })

        setRowCounts(succes.data.totalDocs)
        setData(result_data)
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
      })
  }, [update])

  const handleNextpage = (page) => {
    axios
      .get(`leads?page=${page}&filter=${filter}`)
      .then((succes) => {
        const result_data = succes.data.docs.map((row) => {
          row.id = row._id
          return row
        })

        setData(result_data)
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
      })
  }

  const handleFind = () => {
    setLoading(true)
    axios
      .get(`leads?page=1&filter=${filter}`)
      .then((succes) => {
        const result_data = succes.data.docs.map((row) => {
          row.id = row._id
          return row
        })

        setData(result_data)
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
      })
  }

  const handleExport = () => {
    setLoaderBackdrop(true)
    axios
      .get('/leads/export', {
        headers: { 'Content-type': 'text/csv' },
        responseType: 'blob',
      })
      .then((success) => {
        setLoaderBackdrop(false)
        const url = window.URL.createObjectURL(new Blob([success.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'leads.csv')
        document.body.appendChild(link)
        link.click()
      })
      .catch((err) => {
        console.log(err)
        setLoaderBackdrop(false)
        NotificationManager.error(err.response.data.message)
      })
  }

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    maxSize: '10mb',
    accept: '.csv, application/vnd.ms-excel, text/csv',
    onDrop: (acceptedFiles) => {
      // setLoader(true);

      var formData = new FormData()

      formData.append('csv', acceptedFiles[0])

      axios
        .post('leads/import', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((succes) => {
          NotificationManager.info(succes.data.message, ' Importação')
        })
        .catch((err) => {
          NotificationManager.error('Erro na importação', 'Importação')
        })
    },
  })

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>{/* <GridToolbarExport /> */}</GridToolbarContainer>
    )
  }

  const handleDeleteLead = () => {
    if (rowSelected.length === 0) {
      NotificationManager.info('Selecione um ou mais leads!')
      return false
    }

    axios
      .post('leads/delete', { leads: rowSelected })
      .then((succes) => {
        setUpdate(Math.random())
        setLoading(false)
        NotificationManager.success('Leads deletado com sucesso!')
      })
      .catch((error) => {
        setUpdate(Math.random())
        setLoading(false)
        NotificationManager.error('Erro ao deletar leads!')
      })
  }

  const handleLead2Contact = () => {
    if (rowSelected.length === 0) {
      NotificationManager.info('Selecione um ou mais leads!')
      return false
    }

    axios
      .post('leads/lead2contact', { leads: rowSelected })
      .then((succes) => {
        setUpdate(Math.random())
        setLoading(false)
        NotificationManager.success('Leads salvos com sucesso!')
      })
      .catch((error) => {
        setUpdate(Math.random())
        setLoading(false)
        NotificationManager.error(error.response.data.message)
      })
  }

  const handleOpenDialog = () => {
    setOpenCreateDialog(true)
  }

  const onCloseComposeDialog = () => {
    setOpenCreateDialog(false)
    setCurrentLead(null)
  }

  const handleEdit = () => {
    var currentLead = data.find((row) => row._id == rowSelected[0])
    setCurrentLead(currentLead)
    setOpenCreateDialog(true)
  }

  return (
    <PageContainer
      heading={<IntlMessages id="sidebar.leads" />}
      breadcrumbs={breadcrumbs}
    >
      <GridContainer>
        <Grid item xs={12}>
          <CmtCard className={classes.cardRoot}>
            <CmtCardContent>
              <CSVLink
                data={EXAMPLE.data}
                filename={'exemplo_leads.csv'}
                target="_blank"
                headers={EXAMPLE.headers}
                separator={';'}
              >
                <Button color="default">
                  <SimCardDownloadIcon /> Exemplo
                </Button>
              </CSVLink>
              <Button color="primary" onClick={() => handleExport()}>
                <DownloadIcon /> Exportar
              </Button>

              <Button
                type="button"
                color="inherit"
                onClick={(e) => {}}
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                <FileUploadOutlined /> Importar
              </Button>

              <Box className="Cmt-table-responsive">
                <Header
                  filter={filter}
                  setFilter={setFilter}
                  handleFind={handleFind}
                />
                <DataGrid
                  rows={data}
                  columns={columns}
                  checkboxSelection
                  autoHeight
                  density="compact"
                  paginationMode="server"
                  columnBuffer={2}
                  pageSize={100}
                  rowCount={rowCounts}
                  loading={loading}
                  rowsPerPageOptions={[100]}
                  page={nextPage - 1}
                  onPageChange={(page) => {
                    setLoading(true)
                    handleNextpage(page + 1)
                    setNextPage(page + 1)
                  }}
                  onSelectionModelChange={(selectionModel) => {
                    setRowSelected(selectionModel)
                  }}
                />
              </Box>
              {rowSelected.length === 1 && (
                <Button
                  size="small"
                  variant="text"
                  style={{ color: '#03dac5', paddingTop: '15px' }}
                  onClick={handleEdit}
                >
                  <AiFillEdit style={{ margin: '3px' }} size="15" /> Editar
                </Button>
              )}
              <Button
                size="small"
                variant="text"
                style={{ color: '#1a75ff', paddingTop: '15px' }}
                onClick={handleLead2Contact}
              >
                <IoPersonAddOutline style={{ margin: '3px' }} size="15" />{' '}
                Salvar como Contato
              </Button>
              <Button
                size="small"
                variant="text"
                style={{ color: 'red', paddingTop: '15px' }}
                onClick={handleDeleteLead}
              >
                <RiDeleteBin5Fill style={{ margin: '3px' }} size="15" /> Excluir
              </Button>
            </CmtCardContent>
          </CmtCard>
        </Grid>
        {openCreateDialog && (
          <CreateLead
            open={openCreateDialog}
            handleDialog={onCloseComposeDialog}
            setUpdate={setUpdate}
            currentLead={currentLead}
          />
        )}
        <Tooltip title="Adicionar" aria-label="add">
          <Fab
            className={classes.fab}
            color="secondary"
            aria-label="edit"
            onClick={() => {
              handleOpenDialog()
            }}
          >
            <AddIcon />
          </Fab>
        </Tooltip>
      </GridContainer>
      <NotificationContainer />
      <Backdrop className={classes.backdrop} open={loaderBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </PageContainer>
  )
}

export default Leads
