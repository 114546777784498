import React from 'react'
import CounterCard from '../../../../@jumbo/components/Common/CounterCard'

const TeamsCounterCard = ({ atendentes }) => {
  return (
    <CounterCard
      icon={'/images/dashboards/teamwork.png'}
      number={atendentes}
      label="Atendentes"
      labelProps={{
        fontSize: 16,
      }}
      backgroundColor={['#C076FB -18.96%', '#7717C2 108.17%']}
      gradientDirection="180deg"
    />
  )
}

export default TeamsCounterCard
