import React, { useState, useEffect } from 'react'
import { List } from '@material-ui/core'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItem from '@material-ui/core/ListItem'
import ExportContacts from '../ExportContacts'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import { FaFileDownload } from 'react-icons/fa'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { useDropzone } from 'react-dropzone'
import axios from 'services/auth/jwt/config'
import CircularProgress from '@material-ui/core/CircularProgress'
import Backdrop from '@material-ui/core/Backdrop'
import 'react-notifications/lib/notifications.css'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useDispatch, useSelector } from 'react-redux'
import { getContactsList } from 'redux/actions/ContactApp'
import { CSVLink } from 'react-csv'

const EXAMPLE = {
  data: [
    {
      name: 'Teste',
      phone: '558622373217',
      email: 'teste@teste.com',
      grupos: 'teste | pos vendas',
    },
  ],
  headers: [
    { label: 'name', key: 'name' },
    { label: 'phone', key: 'phone' },
    { label: 'email', key: 'email' },
    { label: 'grupos', key: 'grupos' },
  ],
}

const MySwal = withReactContent(Swal)

const MoreOptions = ({ classes, contactsList }) => {
  const dispatch = useDispatch()
  const { filterType } = useSelector(({ contactApp }) => contactApp)
  const [loader, setLoader] = useState(false)
  const [setores, setSetores] = useState()

  const token = localStorage.getItem('token')
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token

  useEffect(() => {
    axios
      .get('atendimento/departamento')
      .then((success) => {
        var setores_option = success.data.map((row) => {
          return { [row._id]: row.nome }
        })
        setSetores(setores_option)
        setLoader(false)
      })
      .catch((error) => error)
  }, [])

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    maxSize: '10mb',
    accept: '.csv, application/vnd.ms-excel, text/csv',
    onDrop: (acceptedFiles) => {
      setLoader(true)

      var formData = new FormData()

      formData.append('csv', acceptedFiles[0])

      axios
        .post('contact/import', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((succes) => {
          setLoader(false)
          NotificationManager.info(succes.data.message, ' Importação')
          dispatch(getContactsList(filterType))
        })
        .catch((err) => {
          setLoader(false)
          NotificationManager.error('Erro na importação', 'Importação')
          dispatch(getContactsList(filterType))
        })
    },
  })

  const sweetAlerts = () => {
    MySwal.fire({
      title: 'Selecione o departamento!',
      icon: 'question',
      input: 'select',
      inputOptions: setores,
      inputPlaceholder: 'Departamento',
      showCancelButton: true,
      confirmButtonText: 'Continuar!',
      cancelButtonText: 'Cancelar!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        setLoader(true)
        axios
          .get(`contact/importWhatsApp/${result.value}`)
          .then((succes) => {
            setLoader(false)
            dispatch(getContactsList(filterType))
            NotificationManager.info(succes.data.message, 'Importação')
          })
          .catch((err) => {
            setLoader(false)
            NotificationManager.error('Erro ao importar contatos!')
          })
      }
    })
  }

  const exportContact = () => {
    setLoader(true)
    axios
      .get('contact/export', {
        responseType: 'blob',
      })
      .then((success) => {
        const url = window.URL.createObjectURL(new Blob([success.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'contatos.csv')
        document.body.appendChild(link)
        link.click()
        NotificationManager.success('Contatos exportados com sucesso!')
        setLoader(false)
      })
      .catch((err) => {
        setLoader(false)
        NotificationManager.error('Erro ao exportar contatos!')
      })
  }

  const sweetAlertsDeleteAll = () => {
    MySwal.fire({
      title: 'Apagar todos os contatos',
      text: 'Você tem certeza? Essa ação não poderá ser desfeita!',
      icon: 'warning',
      confirmButtonText: 'Continuar!',
      cancelButtonText: 'Cancelar!',
      showCancelButton: true,
      focusCancel: true,
    }).then((result) => {
      if (result.value) {
        setLoader(true)
        axios
          .delete(`contact/deleteAll`)
          .then((succes) => {
            setLoader(false)
            dispatch(getContactsList(filterType))
            NotificationManager.success('Contatos deletados com sucesso!')
          })
          .catch((err) => {
            setLoader(false)
            NotificationManager.error('Erro ao deletar contatos!')
          })
      }
    })
  }

  return (
    <React.Fragment>
      <List>
        <ListItem button className={classes.appNavItem} onClick={sweetAlerts}>
          <ListItemIcon className="Cmt-icon-root">
            <WhatsAppIcon className={classes.WhatsAppIcon} />
          </ListItemIcon>
          <ListItemText className="Cmt-nav-text" primary="Importar WhatsApp" />
        </ListItem>
      </List>
      <List>
        <CSVLink
          data={EXAMPLE.data}
          filename={'exemplo_contatos.csv'}
          target="_blank"
          headers={EXAMPLE.headers}
          separator={';'}
        >
          <ListItem button className={classes.appNavItem}>
            <ListItemIcon className="Cmt-icon-root">
              <FaFileDownload />
            </ListItemIcon>
            <ListItemText
              className="Cmt-nav-text"
              primary="Exportar exemplo.csv"
            />
          </ListItem>
        </CSVLink>
      </List>
      <List {...getRootProps()}>
        <ListItem button className={classes.appNavItem} onClick={() => {}}>
          <ListItemIcon className="Cmt-icon-root">
            <input {...getInputProps()} />
            <CloudUploadIcon />
          </ListItemIcon>
          <ListItemText className="Cmt-nav-text" primary="Importar .csv" />
        </ListItem>
      </List>
      <List>
        {/* <ExportContacts data={contactsList}> */}
        <ListItem button className={classes.appNavItem} onClick={exportContact}>
          <ListItemIcon className="Cmt-icon-root">
            <CloudDownloadIcon />
          </ListItemIcon>
          <ListItemText className="Cmt-nav-text" primary="Exportar" />
        </ListItem>
        {/* </ExportContacts> */}
      </List>
      <List>
        <ListItem
          button
          className={classes.appNavItem}
          onClick={sweetAlertsDeleteAll}
        >
          <ListItemIcon className="Cmt-icon-root">
            <DeleteForeverIcon />
          </ListItemIcon>
          <ListItemText className="Cmt-nav-text" primary="Apagar Todos" />
        </ListItem>
      </List>
      <Backdrop className={classes.backdrop} open={loader}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <NotificationContainer />
    </React.Fragment>
  )
}

export default MoreOptions
