import React, { useEffect, useState } from 'react'
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer'
import IntlMessages from '@jumbo/utils/IntlMessages'
import { Grid } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import CmtCard from '@coremat/CmtCard'
import CmtCardContent from '@coremat/CmtCard/CmtCardContent'
import Alert from '@material-ui/lab/Alert'
import { IoLogoWhatsapp } from 'react-icons/io'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { fade, Typography } from '@material-ui/core'
import { blue, pink } from '@material-ui/core/colors'
import axios from 'services/auth/jwt/config'
import CardOficial from './CardOficial'
import TemplateMessage from './TemplateMessages'
import CmtList from '@coremat/CmtList'
import ItemCell from './ItemCell'
import { MdOutlineAppSettingsAlt } from 'react-icons/md'

const breadcrumbs = [
  { label: 'Home', link: '/' },
  { label: 'WhatsApp' },
  { label: 'Api Business', isActive: true },
]

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    height: '100%',
    marginBottom: 10,
  },
  selectBoxRoot: {
    marginBottom: 6,
    display: 'inline-block',
    '& .MuiInput-underline:before, & .MuiInput-underline:after': {
      display: 'none',
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
    '& .MuiInputBase-root': {
      fontSize: 14,
      color: theme.palette.text.secondary,
    },
  },
  addressTitle: {
    fontWeight: theme.typography.fontWeightRegular,
    [theme.breakpoints.up('lg')]: {
      fontSize: 18,
    },
  },
  socialLink: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: -8,
    marginRight: -8,
  },
  socialLinkCol: {
    paddingLeft: 8,
    paddingRight: 8,
    '& .btn': {
      backgroundColor: fade(theme.palette.primary.main, 0.1),
      color: theme.palette.primary.main,
      padding: 6,
      '& .MuiSvgIcon-root': {
        fontSize: 20,
      },
      '&.twitter': {
        backgroundColor: fade(blue[500], 0.1),
        color: blue[500],
      },
      '&.instagram': {
        backgroundColor: fade(pink[500], 0.1),
        color: pink[500],
      },
      '&.linkedin': {
        backgroundColor: fade(blue[500], 0.1),
        color: blue[500],
      },
    },
  },
  contactRoot: {
    '& .MuiSvgIcon-root': {
      fontSize: 20,
    },
  },
  cardHeaderRoot: {
    paddingTop: 16,
    paddingBottom: 16,
    '& .Cmt-action-default-menu': {
      alignItems: 'flex-start',
      marginTop: 5,
    },
  },
  root: {
    width: '100%',
    textAlign: 'center',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}))

const notifications = [
  {
    id: 2,
    bgColor: '#90e0ef',
    color: '#0077b6',
    content:
      'Para adicionar seu número ao nosso sistema, entre em contato com nosso suporte.',
    showIcon: false,
    icon: <MdOutlineAppSettingsAlt />,
  },
]

const WhatsAppOficial = () => {
  const classes = useStyles()
  const [sessoes, setSessoes] = useState([])
  const [sessaoSelected, setSessaoSelected] = useState()

  const token = localStorage.getItem('token')
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token

  useEffect(() => {
    axios
      .get('whatsapp-oficial/list-session')
      .then((success) => setSessoes(success.data))
      .catch((err) => err)
  }, [])

  const handleSelectSessao = (sessao) => {
    setSessaoSelected(sessao)
  }

  const handleClearSessao = () => {
    setSessaoSelected()
  }

  return (
    <React.Fragment>
      {sessaoSelected ? (
        <TemplateMessage
          sessao={sessaoSelected}
          handleClearSessao={handleClearSessao}
        />
      ) : (
        <PageContainer
          heading={<IntlMessages id="pages.whatsapp.business" />}
          breadcrumbs={breadcrumbs}
        >
          <Grid item xs={12}>
            <CmtCard className={classes.cardRoot}>
              <CmtCardContent>
                <p style={{ textAlign: 'center' }}>
                  <IoLogoWhatsapp size={'30px'} style={{ color: 'green' }} />
                  <h1>Plataforma do WhatsApp Business</h1>
                </p>
                <Grid item xs={12}>
                  <Box
                    className={classes.root}
                    marginTop="15px"
                    marginBottom="15px"
                  >
                    <Typography variant="h3" component="h3">
                      Visão geral
                    </Typography>
                  </Box>
                  <Box
                    className={classes.root}
                    marginTop="15px"
                    marginBottom="15px"
                  >
                    <Typography variant="span" component="span">
                      A API de Nuvem permite que você envie e receba mensagens
                      de clientes por meio de servidores baseados em nuvem da
                      Meta.
                    </Typography>
                    <Typography variant="span" component="span">
                      {' '}
                      As organizações precisam iniciar a verificação da empresa
                      quando estiverem prontas para expandir as conversas
                      iniciadas pela empresa, incluir um número de telefone
                      adicional ou solicitar uma conta comercial oficial
                    </Typography>
                  </Box>
                  <Box
                    className={classes.root}
                    marginTop="15px"
                    marginBottom="15px"
                  >
                    <Typography variant="h2" component="h2">
                      Preços e formas de pagamento
                    </Typography>
                  </Box>
                  <Box
                    className={classes.root}
                    marginTop="15px"
                    marginBottom="15px"
                  >
                    <Typography variant="span" component="span">
                      Agora as empresas são cobradas por conversa, o que inclui
                      todas as mensagens entregues em uma sessão de 24 horas. As
                      primeiras 1.000 conversas mensais são gratuitas. Se você
                      quiser enviar mais de 1.000 conversas, será necessário
                      configurar e anexar uma linha de crédito à conta do
                      WhatsApp Business.{' '}
                      <a href="https://developers.facebook.com/docs/whatsapp/pricing">
                        Saiba mais
                      </a>
                    </Typography>
                  </Box>
                </Grid>
                <Box
                  className={classes.root}
                  marginTop="15px"
                  marginBottom="15px"
                >
                  <Alert severity="info">
                    Saiba mais em:{' '}
                    <a href="https://developers.facebook.com/docs/whatsapp">
                      Facebook Developers
                    </a>
                  </Alert>
                </Box>
              </CmtCardContent>
            </CmtCard>
          </Grid>

          <Box marginTop="15px" marginBottom="15px">
            <Typography variant="h3" component="h3">
              Meus Números
            </Typography>
          </Box>
          {sessoes.length > 0 ? (
            <Box marginTop="15px" marginBottom="15px">
              {sessoes.map((sessao) => (
                <CardOficial
                  sessao={sessao}
                  handleSelectSessao={handleSelectSessao}
                />
              ))}
            </Box>
          ) : (
            <CmtList
              data={notifications}
              renderRow={(item, index) => <ItemCell item={item} key={index} />}
            />
          )}
        </PageContainer>
      )}
    </React.Fragment>
  )
}

export default WhatsAppOficial
