import React, { lazy, Suspense } from 'react'
import { Route, Switch } from 'react-router'
import PageLoader from '../../../@jumbo/components/PageComponents/PageLoader'
import QrCode from './QrCode'
import Oficial from './Oficial'

const ExtraPages = ({ match }) => {
  const requestedUrl = match.url.replace(/\/$/, '')

  return (
    <Suspense fallback={<PageLoader />}>
      <Switch>
        <Route path={`${requestedUrl}/qrcode`} component={QrCode} />
        <Route path={`${requestedUrl}/oficial`} component={Oficial} />
        <Route component={lazy(() => import('../404'))} />
      </Switch>
    </Suspense>
  )
}

export default ExtraPages
