import React from 'react'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Slide from '@material-ui/core/Slide'
import Dialog from '@material-ui/core/Dialog'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Uploads from './index'
import Grid from '@material-ui/core/Grid'
import htmlToDraft from 'html-to-draftjs'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

class CustomOption extends React.Component {
  selectedFile = (file) => {
    this.props.selectedImage(file)
  }

  render() {
    return (
      <React.Fragment>
        <Dialog
          open={this.props.dialogUpload}
          onClose={() => this.props.closeDialogUpload(false)}
          fullScreen
          TransitionComponent={Transition}
        >
          <DialogTitle>Uploads de arquivos</DialogTitle>
          <DialogContent dividers>
            <Grid item xs={12}>
              <Uploads isDialog={true} selectedFile={this.selectedFile} />
            </Grid>
            <Box display="flex" justifyContent="flex-end" mb={4}>
              <Button onClick={() => this.props.closeDialogUpload(false)}>
                Sair
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    )
  }
}

export default CustomOption
