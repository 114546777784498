import React from 'react'
import { emojis } from '../Atendimento/Campanhas/Emojis'

export const config = {
  WhatsApp: {
    options: ['emoji'],
    inline: {
      inDropdown: false,
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
      options: ['bold', 'italic', 'underline'],
    },
    emoji: {
      emojis: emojis,
    },
    image: {
      urlEnabled: true,
      uploadEnabled: true,
      alignmentEnabled: true,
      uploadCallback: undefined,
      previewImage: false,
      inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
      alt: { present: false, mandatory: false },
      defaultSize: {
        height: 'auto',
        width: 'auto',
      },
    },
    list: {
      options: ['unordered', 'ordered'],
    },
  },
}
