import React, { useState, useEffect } from 'react'
import Dialog from '@material-ui/core/Dialog'
import Box from '@material-ui/core/Box'
import GridContainer from '@jumbo/components/GridContainer'
import Grid from '@material-ui/core/Grid'
import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput'
import Button from '@material-ui/core/Button'
import { requiredMessage, emailNotValid } from '@jumbo/constants/ErrorMessages'
import PropTypes from 'prop-types'
import makeStyles from '@material-ui/core/styles/makeStyles'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import { NotificationManager } from 'react-notifications'
import { useDropzone } from 'react-dropzone'
import CmtAvatar from '@coremat/CmtAvatar'
import uuid from 'react-uuid'
import axios from 'services/auth/jwt/config'
import Loader from 'react-loaders'
import NumberFormat from 'react-number-format'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { isValidEmail } from '@jumbo/utils/commonHelper'
import UploadDialog from '../../Files/UplaodFile'
import Switch from '@material-ui/core/Switch'
import { URL_STORAGE } from '../../../../util/socket'

const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    position: 'relative',
  },
  formControl: {
    margin: theme.spacing(2),
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 18,
      color: theme.palette.common.dark,
    },
  },
}))

function NumberFormatCustom(props) {
  const { onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      onValueChange={(values) => {
        onChange(values.formattedValue)
      }}
      format="(##) #####-####"
    />
  )
}

const CreateAtendente = ({
  open,
  handleDialog,
  currentContact,
  userSummary,
  setUserSummary,
  setQtdSetor,
  setUpdate,
}) => {
  const classes = useStyles()

  const [fname, setFname] = useState(currentContact ? currentContact.nome : '')
  const [email, setEmail] = useState(currentContact ? currentContact.email : '')
  const [phone, setPhone] = useState(currentContact ? currentContact.phone : '')
  const [profile_pic, setProfile_pic] = useState(
    currentContact ? currentContact.photo : '',
  )
  const [setor, setSetor] = useState(
    currentContact ? currentContact.departamento : [],
  )
  const [senha, setSenha] = useState(currentContact ? currentContact.senha : '')

  const [isAdmin, setIsAdmin] = useState(
    currentContact ? currentContact.isAdmin : false,
  )

  const [isAtivo, setIsAtivo] = useState(
    currentContact ? currentContact.isAtivo : true,
  )

  const [fnameError, setFnameError] = useState('')
  const [emailError, setEmailError] = useState('')
  const [senhaError, setSenhaError] = useState('')
  const [phoneError, setPhoneError] = useState('')
  const [setorError, setSetorError] = useState('')
  const [loader, setLoader] = useState(false)

  const [setores, setSetores] = useState([])

  const [dialogUpload, closeDialogUpload] = useState(false)

  const token = localStorage.getItem('token')
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token

  const filteredSectors = setores.filter(
    (sect) => !setor.find((dept) => sect._id === dept._id),
  )

  useEffect(() => {
    axios
      .get('atendimento/departamento')
      .then((succes) => {
        setSetores(succes.data)
      })
      .catch((error) => error)
  }, [])

  const checkValidations = () => {
    if (!fname) {
      setFnameError(requiredMessage)
    } else if (!email) {
      setEmailError(requiredMessage)
    } else if (!isValidEmail(email)) {
      setEmailError(emailNotValid)
    } else if (setor.length === 0) {
      setSetorError(requiredMessage)
    } else {
      handleSubmit()
    }
  }

  const handleSubmit = () => {
    setLoader(true)

    var setores_selected = [...new Set(setor.map((row) => row._id))]

    if (currentContact) {
      const data = {
        _id: currentContact._id,
        nome: fname,
        email,
        senha,
        phone,
        isAdmin,
        isAtivo,
        departamento: setores_selected,
        photo: profile_pic,
      }

      axios
        .post('atendimento/atendente/update', data)
        .then((succes) => {
          setUpdate(Math.random())
          handleDialog()
          setLoader(false)
          NotificationManager.success('Atendente salvo com sucesso!')
        })
        .catch((error) => {
          setUpdate(Math.random())
          handleDialog()
          setLoader(false)
          NotificationManager.error(error.response.data.message)
        })
    } else {
      const data = {
        _id: uuid(),
        nome: fname,
        email,
        senha,
        phone,
        isAdmin,
        isAtivo,
        departamento: setor,
        photo: profile_pic,
      }

      axios
        .post('atendimento/atendente', data)
        .then((succes) => {
          setUpdate(Math.random())

          handleDialog()
          setLoader(false)
          NotificationManager.success('Atendente salvo com sucesso!')
        })
        .catch((error) => {
          setUpdate(Math.random())
          setLoader(false)
          handleDialog()
          NotificationManager.error(error.response.data.message)
        })
    }
  }

  const selectedImage = (file) => {
    setProfile_pic(`${URL_STORAGE}/${file.link}`)
    closeDialogUpload(false)
  }

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleDialog}
      className={classes.dialogRoot}
    >
      <DialogTitle className={classes.dialogTitleRoot}>
        {currentContact ? 'Editar Atendente' : 'Criar Atendente'}
      </DialogTitle>
      <DialogContent dividers>
        <Box
          display="flex"
          flexDirection={{ xs: 'column', md: 'row' }}
          alignItems="center"
          mb={{ xs: 6, md: 5 }}
        >
          <Box
            mr={{ xs: 0, md: 5 }}
            mb={{ xs: 3, md: 0 }}
            className="pointer"
            onClick={() => closeDialogUpload(true)}
          >
            <CmtAvatar size={85} src={profile_pic} />
          </Box>
          <GridContainer>
            <Grid item xs={12}>
              <AppTextInput
                fullWidth
                variant="outlined"
                label="Nome"
                value={fname}
                onChange={(e) => {
                  setFname(e.target.value)
                  setFnameError('')
                }}
                helperText={fnameError}
              />
            </Grid>
            <Grid item xs={12}>
              <AppTextInput
                fullWidth
                variant="outlined"
                label="Telefone"
                value={phone}
                onChange={(number) => {
                  setPhone(number)
                  setPhoneError('')
                }}
                helperText={phoneError}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>
          </GridContainer>
        </Box>
        <Box
          display="flex"
          flexDirection={{ xs: 'column', md: 'row' }}
          alignItems="center"
          mb={{ xs: 6, md: 5 }}
        >
          <GridContainer>
            <Grid item xs={12}>
              <AppTextInput
                fullWidth
                variant="outlined"
                label="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value)
                  setEmailError('')
                }}
                helperText={emailError}
              />
            </Grid>
          </GridContainer>
        </Box>
        <Box
          display="flex"
          flexDirection={{ xs: 'column', md: 'row' }}
          alignItems="center"
          mb={{ xs: 6, md: 5 }}
        >
          <GridContainer>
            <Grid item xs={12}>
              <AppTextInput
                fullWidth
                variant="outlined"
                label="Senha"
                type="password"
                value={senha}
                onChange={(e) => {
                  setSenha(e.target.value)
                  setSenhaError('')
                }}
                helperText={senhaError}
              />
            </Grid>
          </GridContainer>
        </Box>
        <Box
          display="flex"
          flexDirection={{ xs: 'column', md: 'row' }}
          alignItems="center"
          mb={{ xs: 6, md: 5 }}
        >
          <GridContainer>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                id="tags-standard"
                options={filteredSectors}
                defaultValue={setor}
                getOptionLabel={(option) => option.nome}
                onChange={(event, newValue) => {
                  setSetorError(false)
                  setSetor(newValue)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Selecione um ou mais departamentos"
                    placeholder="Departamentos"
                    InputProps={{
                      ...params.InputProps,
                    }}
                  />
                )}
              />
              {setorError && (
                <span style={{ color: 'red' }}>
                  Selecione pelo menos um departamento!
                </span>
              )}
            </Grid>
          </GridContainer>
        </Box>
        <Box
          display="flex"
          flexDirection={{ xs: 'column', md: 'row' }}
          alignItems="center"
          mb={{ xs: 6, md: 5 }}
        >
          <GridContainer>
            <Grid item xs={6}>
              <label>Administrador</label>
              <Switch checked={isAdmin} onChange={() => setIsAdmin(!isAdmin)} />
            </Grid>
            <Grid item xs={6}>
              <label>Ativo</label>
              <Switch checked={isAtivo} onChange={() => setIsAtivo(!isAtivo)} />
            </Grid>
          </GridContainer>
        </Box>
        <Box display="flex" justifyContent="flex-end" mb={4}>
          <Button onClick={handleDialog}>Cancelar</Button>
          <Box ml={2}>
            {loader ? (
              <Loader type="ball-pulse-sync" />
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={checkValidations}
              >
                Salvar
              </Button>
            )}
          </Box>
        </Box>
        <UploadDialog
          selectedImage={selectedImage}
          dialogUpload={dialogUpload}
          closeDialogUpload={closeDialogUpload}
        />
      </DialogContent>
    </Dialog>
  )
}

export default CreateAtendente

CreateAtendente.prototype = {
  open: PropTypes.bool.isRequired,
  handleDialog: PropTypes.func,
  selectedContact: PropTypes.object,
}

CreateAtendente.defaultProps = {
  selectedContact: null,
}
