import React from 'react'
import CmtCard from '@coremat/CmtCard'
import { Box } from '@material-ui/core'
import CmtAvatar from '@coremat/CmtAvatar'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'

const useStyles = makeStyles(() => ({
  cardRoot: {
    height: '100%',
    minHeight: 120,
  },
  userBoxRoot: {
    width: 130,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    overflow: 'hidden',
    '&:before': {
      content: '""',
      position: 'absolute',
      top: -34,
      left: -55,
      width: 185,
      height: 185,
      backgroundColor: '#00C4B4',
      borderRadius: '50%',
    },
    '& > div': {
      boxShadow: '0 6px 4px 2px rgba(0,0,0,.2)',
    },
  },
  userBoxRootDisable: {
    width: 130,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    overflow: 'hidden',
    '&:before': {
      content: '""',
      position: 'absolute',
      top: -34,
      left: -55,
      width: 185,
      height: 185,
      backgroundColor: '#e5e7e9',
      borderRadius: '50%',
    },
    '& > div': {
      boxShadow: '0 6px 4px 2px rgba(0,0,0,.2)',
    },
  },
}))

const UserSummery = ({ userSummary, handleEditar, handleDelete }) => {
  const classes = useStyles()

  return (
    <CmtCard className={classes.cardRoot}>
      <Box display="flex" alignItems="center" height={1}>
        <Box
          className={
            userSummary.isActive
              ? classes.userBoxRoot
              : classes.userBoxRootDisable
          }
        >
          <CmtAvatar size={75} alt={userSummary.nome} />
        </Box>
        <Box ml={{ xs: 5, xl: 8 }}>
          <Typography component="div" variant="h4">
            {userSummary.nome}
          </Typography>
          <Box mt={1} color="text.secondary" component="p">
            {userSummary.segmento}
          </Box>
          <Box component="p">
            {userSummary.responsavel}
            <br />
            <Tooltip title="Editar">
              <IconButton
                aria-label="delete"
                onClick={() => {
                  handleEditar(userSummary)
                }}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton
                aria-label="delete"
                onClick={() => {
                  handleDelete(userSummary)
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Box>
    </CmtCard>
  )
}

export default UserSummery
