import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

export default function ScrollDialog({ currentNotification, handleClose }) {
  // const descriptionElementRef = React.useRef(null);
  // React.useEffect(() => {
  //     if (open) {
  //         const { current: descriptionElement } = descriptionElementRef;
  //         if (descriptionElement !== null) {
  //             descriptionElement.focus();
  //         }
  //     }
  // }, [open]);

  return (
    <div>
      <Dialog
        open={currentNotification}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          {currentNotification.title}
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <div
              dangerouslySetInnerHTML={{
                __html: currentNotification.fullDescription,
              }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
