export const ERROR_MESSAGE_ABOVE_LIMIT_CONTACTS_TEST_PERIOD =
  'As campanhas são limitadas em até 100 contatos para as contas em periodo teste.'
export const ERROR_MESSAGE_ABOVE_MAX_CAMPAIGNS_TEST_PERIOD =
  'Limite máximo de 1 campanha atingido para periodo teste!'
export const TITLE_LIST_SHOWMODAL = {
  contacts: 'Limite de contatos atingido.',
  campaigns: 'Limite de campanhas atingido.',
}
export const TYPE_ERROR_CAMPAIGN_PERIOD_TEST = {
  contacts: 'max_contacts_reached',
  campaigns: 'max_campaigns_reached',
}
