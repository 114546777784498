import React from 'react'

import CounterCard from '../../../../@jumbo/components/Common/CounterCard'

const FilesCounterCard = ({ conversas_finalizadas }) => {
  return (
    <CounterCard
      icon={'/images/dashboards/bloquear-bate-papo.png'}
      number={conversas_finalizadas}
      label="Conversas Finalizadas"
      labelProps={{
        fontSize: 12,
      }}
      backgroundColor={['#F25247 -18.96%', '#B72D23 108.17%']}
      gradientDirection="180deg"
    />
  )
}

export default FilesCounterCard
