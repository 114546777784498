import { PageItemCounter } from './pageItemCounter'

export function PageContainerCounter({ items }) {
  const activeCounter = items?.filter(
    (item) =>
      item?.isAtivo === true ||
      item?.isActive === true ||
      item?.isActivated === true,
  )
  const unactiveCounter = items?.filter(
    (item) =>
      item?.isAtivo === false ||
      item?.isActive === false ||
      item?.isActivated === false,
  )

  const itemsCounters = [
    { label: 'Qtd. ativos', qtd: activeCounter?.length },
    { label: 'Qtd. inativos', qtd: unactiveCounter?.length },
    { label: 'Total', qtd: items?.length },
  ]

  const itemsCountersFormatted = itemsCounters?.filter((item) => item.qtd > 0)

  return (
    <div style={{ display: 'flex', gap: '1rem' }}>
      {itemsCountersFormatted?.map((item) => (
        <PageItemCounter label={item.label} counter={item.qtd} />
      ))}
    </div>
  )
}
