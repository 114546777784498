import React from 'react'
import CounterCard from '../../../../@jumbo/components/Common/CounterCard'

const ProjectCounterCard = ({ conversas_ativas }) => {
  return (
    <CounterCard
      icon={'/images/dashboards/web-chat.png'}
      number={conversas_ativas}
      label="Conversas em andamento"
      labelProps={{
        fontSize: 12,
      }}
      backgroundColor={['#8E49F0 -18.96%', '#4904AB 108.17%']}
      gradientDirection="180deg"
    />
  )
}

export default ProjectCounterCard
