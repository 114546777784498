import React, { useEffect } from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import makeStyles from '@material-ui/core/styles/makeStyles'
import ClearIcon from '@material-ui/icons/Clear'
import SyncProblemIcon from '@material-ui/icons/SyncProblem'
import { useDispatch, useSelector } from 'react-redux'
import {
  getContactCountsDuplicates,
  DeleteContactCountsDuplicates,
} from 'redux/actions/ContactApp'

const useStyles = makeStyles((theme) => ({
  duplicateRoot: {
    padding: '10px 24px',
    backgroundColor: '#C8FFF4',
    color: '#018786',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      alignItems: 'center',
      flexDirection: 'row',
    },
  },
  btnRoot: {
    whiteSpace: 'nowrap',
    backgroundColor: '#00C4B4',
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: '#04998d',
      color: theme.palette.common.white,
    },
  },
}))

const DuplicateContactsMsg = ({ toggleDuplicateMsgShow }) => {
  const dispatch = useDispatch()

  const { countDuplicates } = useSelector((state) => state.contactApp)
  const classes = useStyles()

  useEffect(() => {
    dispatch(getContactCountsDuplicates())
  }, [dispatch])

  const handleDuplicateContacts = () => {
    dispatch(DeleteContactCountsDuplicates())
  }

  return (
    <React.Fragment>
      {countDuplicates > 0 && (
        <Box className={classes.duplicateRoot}>
          <Box display="flex" alignItems="center">
            <Box mr={3}>
              <SyncProblemIcon />
            </Box>
            <Box fontSize={14} component="p">
              {`${countDuplicates} contato (s) duplicado (s) encontrado (s)`}
            </Box>
          </Box>
          <Box ml="auto" display="flex" alignItems="center">
            <Box ml={2}>
              <Button
                className={classes.btnRoot}
                variant="contained"
                onClick={handleDuplicateContacts}
              >
                Apagar Duplicados
              </Button>
            </Box>
            <Box ml={2}>
              <IconButton onClick={toggleDuplicateMsgShow}>
                <ClearIcon />
              </IconButton>
            </Box>
          </Box>
        </Box>
      )}
    </React.Fragment>
  )
}

export default DuplicateContactsMsg
