import React, { useState, useEffect } from 'react'
import Dialog from '@material-ui/core/Dialog'
import GridContainer from '@jumbo/components/GridContainer'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import makeStyles from '@material-ui/core/styles/makeStyles'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Typography from '@material-ui/core/Typography'
import Slide from '@material-ui/core/Slide'
import dateformat from 'dateformat'
import Fab from '@material-ui/core/Fab'
import CloseIcon from '@material-ui/icons/Close'
import Tooltip from '@material-ui/core/Tooltip'
import axios from 'services/auth/jwt/config'
import Moment from 'moment'
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useSelector } from 'react-redux'
import DownloadIcon from '@mui/icons-material/Download'
import Button from '@material-ui/core/Button'
import { NotificationManager, NotificationContainer } from 'react-notifications'

const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    position: 'relative',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  fab: {
    position: 'fixed',
    zIndex: '1000 !important',
    bottom: theme.spacing(15),
    right: theme.spacing(12),
  },
  fabPause: {
    position: 'fixed',
    zIndex: '1000 !important',
    bottom: theme.spacing(15),
    right: theme.spacing(12),
  },
  fabCancel: {
    position: 'fixed',
    zIndex: '1000 !important',
    bottom: theme.spacing(30),
    right: theme.spacing(12),
  },
  formControl: {
    margin: theme.spacing(2),
  },
  table: {
    minWidth: 650,
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 18,
      color: theme.palette.common.dark,
    },
  },
}))

const columns = [
  { field: '_id', headerName: 'ID', width: 300 },
  { field: 'destino', headerName: 'Contato', width: 250 },
  {
    field: 'status',
    headerName: 'Status',
    width: 800,
    renderCell: (params) =>
      params.value.includes('ENVIADO') ? (
        <span style={{ color: 'green' }}>{params.value}</span>
      ) : (
        <span style={{ color: 'red' }}>{params.value}</span>
      ),
  },
  {
    field: 'updatedAt',
    headerName: 'Dt. Envio',
    width: 250,
    valueGetter: (params) => Moment(params.value).format('DD/MM/YYYY  HH:mm'),
  },
]

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const Logs = ({ open, handleDialog, currentCampanha }) => {
  const classes = useStyles()

  const [logs, setLogs] = useState([])
  const [options, setOptions] = useState({ page: 1 })
  const [loading, setLoading] = useState(true)
  const [rowCount, setRowCount] = useState()
  const [loader, setLoader] = useState(true)
  const { log } = useSelector((state) => state.CampanhaApp)

  useEffect(() => {
    setLoading(true)
    axios
      .get(`campanha/logs/${currentCampanha._id}?page=${options.page}`)
      .then((success) => {
        setLogs(success.data.docs)
        setRowCount(success.data.totalDocs)
        setLoading(false)
        setLoader(false)
      })
      .catch((err) => err)
  }, [options])

  useEffect(() => {
    var copyLogs = [...logs]
    copyLogs = copyLogs.map((row) => {
      if (row._id == log._id) {
        row.status = log.status
        row.updatedAt = log.updatedAt
        return row
      }
    })
  }, [log])

  const handleExport = () => {
    setLoader(true)
    axios
      .get(`/campanha/logs/export/${currentCampanha._id}`, {
        headers: { 'Content-type': 'text/csv' },
        responseType: 'blob',
      })
      .then((success) => {
        setLoader(false)
        const url = window.URL.createObjectURL(new Blob([success.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'logs_campanha.csv')
        document.body.appendChild(link)
        link.click()
      })
      .catch((err) => {
        console.log(err)
        setLoader(false)
        if (err.response.data.message) {
          NotificationManager.error(err.response.data.message)
        } else {
          NotificationManager.error('Erro ao obter logs!')
        }
      })
  }

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <Button color="primary" onClick={() => handleExport()}>
          <DownloadIcon /> Exportar
        </Button>

        {/* <GridToolbarExport /> */}
      </GridToolbarContainer>
    )
  }

  return (
    <Dialog
      open={open}
      onClose={handleDialog}
      fullScreen
      className={classes.dialogRoot}
      TransitionComponent={Transition}
    >
      <DialogTitle className={classes.dialogTitleRoot}>
        Resultado do último envio
      </DialogTitle>
      <DialogContent dividers>
        {currentCampanha.last_send ? (
          <React.Fragment>
            <GridContainer>
              <Grid item xs={12} style={{ alignItems: 'center' }}>
                Dt. último envio:{' '}
                <b>
                  {dateformat(currentCampanha.last_send, 'dd/mm/yyyy HH:MM:ss')}
                </b>
              </Grid>
              {currentCampanha.mensagem && (
                <Grid item xs={12}>
                  <Typography
                    component="div"
                    align="center"
                    variant="h3"
                    style={{ color: 'red' }}
                  >
                    {currentCampanha.mensagem}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12}>
                {logs && logs.length > 0 && (
                  <div style={{ height: '500px', width: '100%' }}>
                    <DataGrid
                      rows={logs}
                      columns={columns}
                      columnBuffer={2}
                      pageSize={50}
                      loading={loading}
                      paginationMode="server"
                      rowCount={rowCount}
                      onPageChange={(page) => {
                        var optionsCopy = { ...options }
                        optionsCopy.page = page + 1
                        setOptions(optionsCopy)
                      }}
                      disableSelectionOnClick
                      rowsPerPageOptions={[50]}
                      components={{
                        Toolbar: CustomToolbar,
                      }}
                    />
                  </div>
                )}
              </Grid>
            </GridContainer>
          </React.Fragment>
        ) : (
          <Grid item xs={12}>
            <Typography
              component="div"
              align="center"
              variant="h2"
              style={{ color: '#867979' }}
            >
              Você ainda não fez nenhum envio!
            </Typography>
          </Grid>
        )}
      </DialogContent>
      <Tooltip title="Sair" aria-label="add" placement="left">
        <Fab
          className={classes.fab}
          aria-label="exit"
          size="small"
          style={{ backgroundColor: '#de5646', color: 'whitesmoke' }}
          onClick={handleDialog}
        >
          <CloseIcon />
        </Fab>
      </Tooltip>
      <NotificationContainer />
      <Backdrop className={classes.backdrop} open={loader}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Dialog>
  )
}

export default Logs

Logs.prototype = {
  open: PropTypes.bool.isRequired,
  handleDialog: PropTypes.func,
  selectedContact: PropTypes.object,
}

Logs.defaultProps = {
  selectedContact: null,
}
