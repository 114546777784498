import React, { useEffect, useState } from 'react'
import Box from '@material-ui/core/Box'
import Switch from '@material-ui/core/Switch'
import { Button } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

import 'react-notifications/lib/notifications.css'

const MySwal = withReactContent(Swal)

const useStyles = makeStyles((theme) => ({
  cardServicesView: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
  },
  cardServices: {
    display: 'flex',
    alignItems: 'center',
  },
  formControl: {
    margin: theme.spacing(1),
  },
  cardServicesItem: {
    width: '33.33%',
    textAlign: 'center',
    paddingLeft: 20,
    paddingRight: 20,
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  cardContentRoot: {
    paddingTop: 24,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: theme.palette.text.secondary,
  },
}))

const UserInfo = ({
  userDetails,
  userFunctions,
  imagens,
  handleImagem,
  imagemSelected,
}) => {
  const classes = useStyles()

  return (
    <Box>
      <Box className={classes.cardServicesView}>
        <Box className={classes.cardServices}>
          {Object.keys(userDetails.stats).map((item, index) => (
            <Box className={classes.cardServicesItem} key={index}>
              <Box
                component="span"
                fontSize={12}
                color="text.secondary"
                display="block"
                mb={1}
                className={classes.capitalize}
              >
                {item}
              </Box>
              <Box
                component="span"
                fontSize={14}
                color="text.primary"
                display="block"
              >
                {userDetails.stats[item]}
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
      <Box className={classes.cardContentRoot}>
        {userDetails.conected ? (
          <React.Fragment>
            {/*  <Button
              color="primary"
              onClick={userFunctions.sincronizarMensagens}

            >
             Sincronizar Mensagens
            </Button>
          */}
            <Box className={classes.cardServicesView}>
              <InputLabel id="demo-simple-select-label">
                Direcionar conversas apenas para este setor?
                <Switch
                  checked={userFunctions.isExclusiveSector}
                  onChange={userFunctions.handleChangeExclusiveSector}
                />
              </InputLabel>
            </Box>
            <Button
              color="primary"
              onClick={userFunctions.handleChangeReiniciarSessao}
            >
              Reiniciar Sessão
            </Button>
            {/* <Button
              color="secondary"
              onClick={userFunctions.handleChangeStopSessao}
            >
              Pausar Sessão
            </Button> */}
            <Button
              color="default"
              onClick={userFunctions.handleChangeExcluirSessao}
              style={{ color: 'red' }}
            >
              Excluir sessão
            </Button>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {!userFunctions.readQrCode && imagens.length > 1 && (
              <FormControl
                className={classes.formControl}
                style={{ width: '30%' }}
              >
                <InputLabel id="demo-simple-select-label">
                  Selecione uma versão
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={imagemSelected}
                  onChange={handleImagem}
                >
                  {imagens.map((row, key) => (
                    <MenuItem key={row.nome} value={row._id}>
                      {row.nome}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <Button
              color="default"
              onClick={userFunctions.handleChangeExcluirSessao}
              style={{ color: 'red' }}
            >
              Excluir sessão
            </Button>
            {!userFunctions.readQrCode && (
              <Button
                color="primary"
                onClick={userFunctions.handleChangeQrCode}
              >
                Ler QrCode
              </Button>
            )}
          </React.Fragment>
        )}
      </Box>
    </Box>
  )
}

export default UserInfo
