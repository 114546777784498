import React, { useState, useEffect } from 'react'
import Dialog from '@material-ui/core/Dialog'
import Box from '@material-ui/core/Box'
import GridContainer from '@jumbo/components/GridContainer'
import Grid from '@material-ui/core/Grid'
import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput'
import Button from '@material-ui/core/Button'
import { requiredMessage, emailNotValid } from '@jumbo/constants/ErrorMessages'
import PropTypes from 'prop-types'
import makeStyles from '@material-ui/core/styles/makeStyles'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import { NotificationManager } from 'react-notifications'
import { useDropzone } from 'react-dropzone'
import CmtAvatar from '@coremat/CmtAvatar'
import uuid from 'react-uuid'
import axios from 'services/auth/jwt/config'
import Loader from 'react-loaders'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import Editor from './Editor'
import Slide from '@material-ui/core/Slide'
import { config } from './configEditor'
import Chip from '@material-ui/core/Chip'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CircularProgress from '@material-ui/core/CircularProgress'
import { convertToRaw, EditorState, convertFromRaw } from 'draft-js'
import Tooltip from '@material-ui/core/Tooltip'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import CloseIcon from '@material-ui/icons/Close'
import SaveIcon from '@material-ui/icons/Save'
import AppRadioButton from '@jumbo/components/Common/formElements/AppRadioButton'
import { VariableRow } from './VariableRow'
import { useHistory } from 'react-router-dom'
import {
  ERROR_MESSAGE_ABOVE_LIMIT_CONTACTS_TEST_PERIOD,
  ERROR_MESSAGE_ABOVE_MAX_CAMPAIGNS_TEST_PERIOD,
  TITLE_LIST_SHOWMODAL,
  TYPE_ERROR_CAMPAIGN_PERIOD_TEST,
} from './constants'
import { useCheckLimit } from './useCheckLimit'

const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    position: 'relative',
  },
  formControl: {
    margin: theme.spacing(2),
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 18,
      color: theme.palette.common.dark,
    },
  },
  fab: {
    position: 'fixed',
    zIndex: '1000 !important',
    bottom: theme.spacing(8),
    right: theme.spacing(10),
  },
  fab2: {
    position: 'fixed',
    zIndex: '1000 !important',
    bottom: theme.spacing(22),
    right: theme.spacing(8),
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const CreateAtendente = ({
  open,
  handleDialog,
  currentCampanha,
  userSummary,
  setUserSummary,
  setUpdate,
}) => {
  const classes = useStyles()
  const [schedule, setSchedule] = useState(currentCampanha?.schedule || null)
  const [setor, setSetor] = useState(
    currentCampanha?.departamento?._id ? currentCampanha.departamento._id : '',
  )
  const [editorState, setEditorState] = useState(
    currentCampanha?.bodyCampanha
      ? EditorState.createWithContent(
          convertFromRaw(JSON.parse(currentCampanha.bodyCampanha)),
        )
      : EditorState.createEmpty(),
  )
  const [name, setName] = useState(currentCampanha ? currentCampanha.name : '')
  const [typeCampanha, setTypeCampanha] = useState(
    currentCampanha ? currentCampanha.typeCampanha : '',
  )
  const [selected, setSelected] = useState(
    currentCampanha ? currentCampanha.to : [],
  )

  const [editorConfig, setEditorConfig] = useState(
    currentCampanha ? config[currentCampanha.typeCampanha] : null,
  )

  const [setorError, setSetorError] = useState('')
  const [contentError, setContentError] = useState('')
  const [errorTypeCampanha, setErrorTypeCampanha] = useState()
  const [fnameError, setFnameError] = useState('')
  const [errorSelected, setErrorSelected] = useState()
  const [loader, setLoader] = useState(false)
  const [setores, setSetores] = useState([])
  const [contatos, setContatos] = useState([])
  const [loading, setLaoding] = useState(true)

  const history = useHistory()
  const { showModal } = useCheckLimit()

  const token = localStorage.getItem('token')
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token

  useEffect(() => {
    setLoader(false)
    axios
      .get('atendimento/departamento')
      .then((succes) => {
        setSetores(succes.data)
      })
      .catch((error) => error)
  }, [])

  useEffect(() => {
    axios
      .get('contact/campanha')
      .then((succes) => {
        setContatos(succes.data)
        setLaoding(false)
      })
      .catch((error) => setLaoding(false))
  }, [])

  const checkValidations = () => {
    if (loader) {
      return
    }
    const rawData = convertToRaw(editorState.getCurrentContent())
    let isContentEmpty = true
    for (const block of rawData.blocks) {
      if (block.text?.trim()) {
        isContentEmpty = false
      }
    }
    if (isContentEmpty) {
      setContentError(requiredMessage)
    } else if (!name) {
      setFnameError(requiredMessage)
    } else if (!selected) {
      setErrorSelected(requiredMessage)
    } else if (!setor) {
      setSetorError(requiredMessage)
    } else {
      handleSubmit()
    }
  }

  const handleSubmit = () => {
    setLoader(true)

    var data
    if (currentCampanha) {
      data = {
        _id: currentCampanha._id,
        name,
        departamento: setor,
        typeCampanha,
        status: 'Ver logs',
        bodyCampanha: JSON.stringify(
          convertToRaw(editorState.getCurrentContent()),
        ),
        to: selected,
        schedule,
      }
      axios
        .post('campanha/update', data)
        .then((succes) => {
          setUpdate(Math.random())
          setLoader(false)
          NotificationManager.success('Campanha salva com sucesso!')
        })
        .catch((error) => {
          setUpdate(Math.random())
          handleDialog()
          setLoader(false)
          NotificationManager.error(error.response.data.message)
          if (
            error.response.data.errorType ===
            TYPE_ERROR_CAMPAIGN_PERIOD_TEST.contacts
          ) {
            showModal(
              error.response.data.message,
              TITLE_LIST_SHOWMODAL.contacts,
            )
          }
        })
      handleDialog()
    } else {
      data = {
        _id: uuid(),
        name,
        status: 'Ver logs',
        typeCampanha,
        bodyCampanha: JSON.stringify(
          convertToRaw(editorState.getCurrentContent()),
        ),
        to: selected,
        departamento: setor,
        schedule,
      }
      axios
        .post('campanha', data)
        .then((succes) => {
          setUpdate(Math.random())

          handleDialog()
          setLoader(false)
          NotificationManager.success('Campanha criada com sucesso!')
        })
        .catch((error) => {
          setLoader(false)
          NotificationManager.error(error.response.data.message)
          if (
            error.response.data.errorType ===
            TYPE_ERROR_CAMPAIGN_PERIOD_TEST.contacts
          ) {
            showModal(
              error.response.data.message,
              TITLE_LIST_SHOWMODAL.contacts,
            )
          }
          if (
            error.response.data.errorType ===
            TYPE_ERROR_CAMPAIGN_PERIOD_TEST.campaigns
          ) {
            showModal(
              error.response.data.message,
              TITLE_LIST_SHOWMODAL.campaigns,
            )
          }
        })
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleDialog}
      fullScreen
      className={classes.dialogRoot}
      TransitionComponent={Transition}
    >
      <DialogTitle className={classes.dialogTitleRoot}>
        {currentCampanha ? 'Editar Campanha' : 'Criar Campanha'}
      </DialogTitle>
      <DialogContent dividers>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Selecione o canal da campanha
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={typeCampanha}
              error={errorTypeCampanha}
              onChange={(e) => {
                setTypeCampanha(e.target.value)
                setEditorConfig(config[e.target.value])
                setErrorTypeCampanha(false)
              }}
            >
              <MenuItem key="WhatsApp" value="WhatsApp">
                WhatsApp
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {typeCampanha && (
          <React.Fragment>
            <Box
              display="flex"
              flexDirection={{ xs: 'column', md: 'row' }}
              alignItems="center"
              mb={{ xs: 6, md: 5 }}
            >
              <GridContainer>
                <Grid item xs={12}>
                  <Editor
                    config={editorConfig}
                    editorState={editorState}
                    setEditorState={(v) => {
                      setEditorState(v)
                      setContentError('')
                    }}
                  />
                  {contentError?.trim() ? (
                    <p style={{ fontSize: '12px', color: 'red' }}>
                      {contentError}
                    </p>
                  ) : null}
                </Grid>
              </GridContainer>
            </Box>
            <Box style={{ marginBottom: '1rem' }}>
              <p style={{ fontWeight: 'bold' }}>
                Personalize suas mensagens com as variáveis abaixo, basta copiar
                e colar no seu texto:
              </p>

              <div style={{ marginLeft: '1rem' }}>
                <VariableRow
                  value={'[saudacao]'}
                  text={
                    'se ajusta ao dia e horário, apresentando mensagens como "Bom dia, como vai?" ou "Boa tarde, tudo bem?'
                  }
                />
                <VariableRow
                  value={'[nome]'}
                  text="substitui o texto pelo nome do
                  contato, personalizando a mensagem para cada destinatário"
                />
              </div>
            </Box>
            <Grid item xs={12}>
              <AppTextInput
                fullWidth
                label="Nome da Campanha"
                value={name}
                onChange={(e) => {
                  setName(e.target.value)
                  setFnameError('')
                }}
                helperText={fnameError}
              />
            </Grid>
            <Box
              display="flex"
              flexDirection={{ xs: 'column', md: 'row' }}
              alignItems="center"
              mb={{ xs: 6, md: 5 }}
            >
              <GridContainer>
                <Grid item xs={12}>
                  <Autocomplete
                    multiple
                    id="tags-standard"
                    options={contatos}
                    defaultValue={selected}
                    getOptionLabel={(option) =>
                      option.name || option.phones[0].phone || option.chatId
                    }
                    groupBy={(option) => option.type}
                    onChange={(event, newValue) => {
                      setErrorSelected('')
                      setSelected(newValue)
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Selecione o destino - Contatos e grupos"
                        placeholder="Contatos, grupos e Etiquetas"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                  {errorSelected && (
                    <span style={{ color: 'red' }}>
                      Selecione pelo menos um contato ou grupo!
                    </span>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Selecione um Setor
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={setor}
                      error={setorError}
                      onChange={(e) => {
                        setSetor(e.target.value)
                        setSetorError(false)
                      }}
                    >
                      {setores.map((row, key) => (
                        <MenuItem key={row.nome} value={row._id}>
                          {row.nome}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <p>Agendar campanha</p>
                  <input
                    style={{
                      height: '2rem',
                      fontFamily: 'Helvetica',
                    }}
                    type="datetime-local"
                    value={schedule}
                    onChange={(v) => setSchedule(v.target.value)}
                    id="schedule"
                    name="schedule"
                    min={new Date().toISOString().slice(0, 16)}
                  />
                </Grid>
              </GridContainer>
            </Box>
          </React.Fragment>
        )}
      </DialogContent>
      {(loader || typeCampanha) && (
        <Tooltip title="Salvar" aria-label="add" placement="left">
          <Fab
            className={classes.fab2}
            color="secondary"
            aria-label="add"
            onClick={checkValidations}
          >
            <SaveIcon />
          </Fab>
        </Tooltip>
      )}
      <Tooltip title="Sair" aria-label="add" placement="left">
        <Fab
          className={classes.fab}
          aria-label="exit"
          size="small"
          style={{ backgroundColor: '#de5646', color: 'whitesmoke' }}
          onClick={handleDialog}
        >
          <CloseIcon />
        </Fab>
      </Tooltip>
    </Dialog>
  )
}

export default CreateAtendente

CreateAtendente.prototype = {
  open: PropTypes.bool.isRequired,
  handleDialog: PropTypes.func,
  selectedContact: PropTypes.object,
}

CreateAtendente.defaultProps = {
  selectedContact: null,
}
