import React, { useState, useEffect } from 'react'
import GridContainer from '@jumbo/components/GridContainer'
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer'
import Box from '@material-ui/core/Box'
import IntlMessages from '@jumbo/utils/IntlMessages'
import Grid from '@material-ui/core/Grid'
import { MdDeleteForever } from 'react-icons/md'
import { AiOutlineFileZip } from 'react-icons/ai'
import makeStyles from '@material-ui/core/styles/makeStyles'
import CmtCard from '@coremat/CmtCard'
import CmtCardContent from '@coremat/CmtCard/CmtCardContent'
import CmtProgressBar from '@coremat/CmtProgressBar'
import { useDropzone } from 'react-dropzone'
import { Typography, Button } from '@material-ui/core'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import LinearProgress from '@material-ui/core/LinearProgress'
import axios from 'services/auth/jwt/config'
import UserPhotos from './Photos'
import Skeleton from '@material-ui/lab/Skeleton'
import { AiOutlineCloudServer } from 'react-icons/ai'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import { URL, URL_SOCKET } from '../../../util/socket'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useSelector } from 'react-redux'
import socketIOClient from 'socket.io-client'

const MySwal = withReactContent(Swal)

const breadcrumbs = [
  { label: 'Home', link: '/' },
  { label: 'Uploads', isActive: true },
]

const useStyles = makeStyles((theme) => ({
  pageFull: {
    width: '100%',
  },
  cardRoot: {
    height: '100%',
    marginBottom: 10,
  },
  userPhotosRoot: {
    '& .scrollbar-container': {
      height: '272px !important',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  fab: {
    position: 'fixed',
    zIndex: '1000 !important',
    bottom: theme.spacing(8),
    right: theme.spacing(8),
  },
  absolute: {
    position: 'absolute',
    bottom: theme.spacing(4),
    right: theme.spacing(6),
  },
}))
const CircularProgressLabel = ({ text }) => (
  <Box sx={{ position: 'relative', display: 'inline-flex' }}>
    <CircularProgress
      variant="indeterminate"
      color="primary"
      disableShrink
      size={24}
      style={{ marginRight: '8px', marginBottom: '4px' }}
    />
    <Box
      sx={{
        display: 'flex',
      }}
    >
      <Typography
        variant="caption"
        component="div"
        color="textPrimary"
        style={{
          animation: 'animate 2s linear infinite',
        }}
      >
        {`${text}`}
      </Typography>
    </Box>
  </Box>
)

const Files = ({ isDialog, selectedFile, origem = false }) => {
  const classes = useStyles()
  const [loader, setLoader] = useState(false)
  const [loaderFiles, setLoaderFiles] = useState(true)
  const [files, setFiles] = useState([])
  const [size, setSize] = useState({
    limit: 0,
    limit_formated: '0 GB',
    size: 0,
    size_formated: '0 GB',
  })
  const [nextPage, setNextPage] = useState(2)
  const [loaderBackdrop, setLoaderBackDrop] = useState()
  const [loaderGenerateBackup, setLoaderGenerateBackup] = useState(false)
  const [loaderDeleteAll, setLoaderDeleteAll] = useState(false)
  const [trash, setTrash] = useState()
  const [textLoader, setTextloader] = useState('')

  const { authUser } = useSelector(({ auth }) => auth)
  const canDeleteFiles = true
  // console.log(authUser)

  const token = localStorage.getItem('token')
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token

  useEffect(() => {
    axios
      .get(`files/1`)
      .then((succes) => {
        setNextPage(2)
        var copyFiles = [...files, ...succes.data.docs]
        setFiles(copyFiles)
        setLoaderFiles(false)
      })
      .catch((error) => {
        NotificationManager.error('Erro ao buscar aquivos!')
        setLoaderFiles(false)
      })

    axios
      .get('file/status')
      .then((success) => {
        setSize(success.data)
      })
      .catch((err) => err)
  }, [trash])

  useEffect(() => {
    if (!authUser?._id) return
    const socket = socketIOClient(URL_SOCKET, {
      query: {
        id: authUser._id,
        origin: 'uploads',
      },
      transports: ['websocket'],
    })

    socket.on('connect', () => {
      console.log(`[CLIENT_SOCKET_IO] Connected:`, socket.connected)
    })

    socket.on('log_backup_delete', (data) => {
      if (data) {
        if (data.isLoadBackup || data.isLoadDeleteAll) {
          setLoaderDeleteAll(true)
          setLoaderGenerateBackup(true)
          setTextloader(data.observacao)
        } else {
          setLoaderDeleteAll(false)
          setLoaderGenerateBackup(false)
          setTextloader('')

          if (data.type === 'backup') {
            if (data.isError) {
              MySwal.fire({
                title: 'Backup',
                icon: 'warning',
                html:
                  data.observacao +
                  '<br/><br/>' +
                  '<a href="' +
                  data.last_link +
                  '" target="_blank"><strong>Baixar o arquivo</strong></a>',
                showConfirmButton: true,
              })
            } else {
              MySwal.fire({
                title: data.observacao,
                icon: 'success',
                html:
                  '<a href="' +
                  data.last_link +
                  '" target="_blank"><strong>Baixar o arquivo</strong></a>',
                showConfirmButton: true,
              }).then((confirm) => window.location.reload())
            }
          } else {
            // delete
            if (data.isError) {
              MySwal.fire('Erro!', data.observacao, 'error')
            } else {
              MySwal.fire(
                'Sucesso!',
                data.observacao,
                'success',
              ).then((confirm) => window.location.reload())
            }
          }
        }
      } else {
        setLoaderDeleteAll(false)
        setLoaderGenerateBackup(false)
        setTextloader('')
      }
    })
    return () => socket.disconnect()
  }, [])

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxSize: 10485760 * 5,
    onDrop: (acceptedFiles) => {
      if (!acceptedFiles) return false

      setLoader(true)

      var formData = new FormData()

      formData.append('file', acceptedFiles[0])
      formData.append('origem', origem)

      axios
        .post('file/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((succes) => {
          acceptedFiles.pop()
          var copyFile = [succes.data.file, ...files]
          setFiles(copyFile)
          setLoader(false)
          NotificationManager.success(succes.data.message)
        })
        .catch((err) => {
          acceptedFiles.pop()
          setLoader(false)
          NotificationManager.error('Erro ao salvar aquivo!')
        })
    },
    onDropRejected: (file) => {
      NotificationManager.error('Arquivo maior que 50MB!')
    },
  })

  const handleDelete = (file) => {
    setLoader(true)
    axios
      .delete(`files/${file._id}`)
      .then((succes) => {
        var copyFiles = [...files]
        copyFiles = copyFiles.filter((row) => row._id != file._id)
        setFiles(copyFiles)
        setLoader(false)
        NotificationManager.success(succes.data.message)
      })
      .catch((err) => {
        setLoader(false)
        NotificationManager.error('Erro ao deletar aquivo!')
      })
  }

  const handleCopy = (file) => {
    var link = URL + '/' + file.link
    navigator.clipboard.writeText(link).then((text) => {
      NotificationManager.success(`Link copiado!`)
    })
  }

  const getBackup = () => {
    MySwal.fire(
      'Atenção!',
      'Entre em contato com o nosso suporte para solicitar o backup dos arquivos.',
      'info',
    )

    // MySwal.fire({
    //   title: 'Backup em andamento!',
    //   icon: 'info',
    //   html:
    //     `O backup está sendo gerado ele será disponibilizado nessa página e enviaremos um link por email assim que o backup estiver pronto.`,
    //   showCloseButton: true,
    //   focusConfirm: true,
    //   confirmButtonText: 'Ok'
    // }).then(confirm => {
    //   setLoaderDeleteAll(true)
    //   setLoaderGenerateBackup(true)
    //   axios.get("/zipFiles")
    //     .then(success => success)
    //     .catch(err => {
    //       console.log(err)
    //       setLoaderDeleteAll(false)
    //       setLoaderGenerateBackup(false)
    //       MySwal.fire('Erro', 'Erro ao gerar backup!', 'error')
    //     })
    // })
  }

  const deleteAll = () => {
    MySwal.fire({
      title: 'Apagar todos os arquivos',
      icon: 'warning',
      html:
        '<ul style="font-size:13px;">' +
        '<li>Essa ação <strong>removerá todos</strong> os seus arquivos, <strong>exceto os arquivos que estão sendo utilizados nas campanhas e no robô.</strong></li>' +
        '<li>Essa ação não poderá ser desfeita.</li>' +
        '<li>Os arquivos do celular não serão deletados.</li>' +
        '<li>Fotos de perfil das conversas não serão deletadas.</li>' +
        '</ul>',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonText: 'Apagar',
      cancelButtonText: 'Voltar',
      focusCancel: true,
    }).then((success) => {
      if (success.isConfirmed) {
        setLoaderDeleteAll(true)
        setLoaderGenerateBackup(true)
        axios
          .get('/deleteAllFiles')
          .then((succes) => succes)
          .catch((err) => {
            setLoaderDeleteAll(false)
            setLoaderGenerateBackup(false)
            MySwal.fire('Erro', 'Erro ao deletar arquivos!', 'error')
          })
      }
    })
  }

  const handleNextPage = () => {
    if (!loaderBackdrop && nextPage) {
      setLoaderBackDrop(true)
      axios
        .get(`files/${nextPage}`)
        .then((succes) => {
          var copyFiles = [...files, ...succes.data.docs]
          setFiles(copyFiles)
          setNextPage(succes.data.nextPage)
          setLoaderBackDrop(false)
        })
        .catch((error) => {
          NotificationManager.error('Erro ao buscar aquivos!')
          setLoaderBackDrop(false)
        })
    }
  }

  return (
    <PageContainer
      heading={isDialog ? null : <IntlMessages id="sidebar.minhaconta.files" />}
      breadcrumbs={isDialog ? null : breadcrumbs}
    >
      <GridContainer>
        <Grid item xs={12}>
          <CmtCard className={classes.cardRoot}>
            <CmtCardContent>
              <Box
                className={classes.root}
                marginTop="10px"
                marginBottom="10px"
              >
                <Typography variant="h4" align="center" color="#808080">
                  <AiOutlineCloudServer size="20px" /> Espaço utilizado
                </Typography>
                <CmtProgressBar
                  onlyContained={false}
                  thickness={4}
                  pointer={false}
                  pointerSize={12}
                  labelPos="top-left"
                  hideValue={false}
                  valuePos="right"
                  value={size.size}
                  total={size.limit}
                  renderValue={(value, total) => {
                    return size.sizeFormated + '/ ' + size.limit_formated
                  }}
                  emptyColor={'#e9ecef'}
                  containedColor="#1a90ff"
                />
              </Box>
              <Box
                className={classes.root}
                marginTop="10px"
                marginBottom="10px"
              >
                <Box {...getRootProps({ className: 'dropzone' })}>
                  <input {...getInputProps()} />
                  <Typography>
                    <i>Clique ou arraste aqui seu arquivo Máximo 50MB</i>
                  </Typography>
                </Box>
              </Box>
              <Box
                className={classes.root}
                marginTop="10px"
                marginBottom="10px"
                sx={{ '& button': { m: 1 } }}
                style={{ display: 'flex' }}
              >
                <div style={{ position: 'relative' }}>
                  {canDeleteFiles ? (
                    <div
                      style={{
                        position: 'absolute',
                        top: '-4px',
                        right: '-14px',
                        fontSize: '9px',
                        color: '#6c19ef',
                        backgroundColor: '#6c19ef2e',
                        padding: '2px 4px',
                        borderRadius: '5px',
                      }}
                    >
                      NOVO!
                    </div>
                  ) : null}
                  <Button
                    variant="text"
                    disabled={!canDeleteFiles}
                    startIcon={<MdDeleteForever />}
                    style={{
                      marginLeft: '10px',
                      color: loaderDeleteAll ? '#979797' : '#FF5733',
                    }}
                    onClick={deleteAll}
                  >
                    Apagar todos
                  </Button>
                </div>
              </Box>

              {(loaderGenerateBackup || loaderDeleteAll) && (
                <CircularProgressLabel
                  style={{ marginLeft: '10px' }}
                  text={textLoader}
                />
              )}

              {loader && (
                <LinearProgress color="secondary" variant="indeterminate" />
              )}
            </CmtCardContent>
          </CmtCard>
        </Grid>
      </GridContainer>
      <GridContainer>
        <Grid item xs={12}>
          <CmtCard className={classes.cardRoot}>
            <CmtCardContent>
              <Box
                className={classes.root}
                marginTop="10px"
                marginBottom="10px"
              >
                {loaderFiles ? (
                  <Skeleton variant="rect" width={100} height={100} />
                ) : files.length > 0 ? (
                  <UserPhotos
                    files={files}
                    handleDelete={handleDelete}
                    handleCopy={handleCopy}
                    isDialog={isDialog}
                    selectedFile={selectedFile}
                    handleNextPage={handleNextPage}
                  />
                ) : (
                  <Typography variant="h3" align="center" color="#808080">
                    Você não possui arquivos, faça upload para começar.
                  </Typography>
                )}
              </Box>
            </CmtCardContent>
          </CmtCard>
        </Grid>
      </GridContainer>
      <NotificationContainer />
      <Backdrop className={classes.backdrop} open={loaderBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </PageContainer>
  )
}

export default Files
