import { useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export function useCheckLimit() {
  const history = useHistory()
  const MySwal = withReactContent(Swal)
  const showModal = (message, title) => {
    MySwal.fire({
      title,
      html: message,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ir para planos',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
    }).then((success) => {
      if (success.isConfirmed) {
        history.push('/app/invoice')
      }
    })
  }
  return { showModal }
}
