import React, { useState, useEffect } from 'react'
import CmtObjectSummary from '@coremat/CmtObjectSummary'
import CmtAvatar from '@coremat/CmtAvatar'
import UserInfo from './UserInfo'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import LinearProgress from '@material-ui/core/LinearProgress'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import clsx from 'clsx'
import { useSelector } from 'react-redux'
import axios from 'services/auth/jwt/config'
import socketIOClient from 'socket.io-client'
import { URL_SOCKET } from 'util/socket'
import CmtList from '@coremat/CmtList'
import ItemCell from '../../Atendimento/setor/ItemCell'
import { MdWork } from 'react-icons/md'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { sendParentEvent } from 'util/sendParentEvent'

const notifications = [
  {
    id: 2,
    bgColor: '#F2E7FE',
    color: '#7F39FB',
    content:
      'Não há departamentos cadastrados, para começar adicione um novo departamento!',
    showIcon: false,
    icon: <MdWork />,
  },
]

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    position: 'relative',
    '& .Cmt-header-root': {
      paddingBottom: 0,
    },
  },
  cardServicesView: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
  },
  userDetailRoot: {
    position: 'relative',
    width: 112,
    height: 112,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 25,
  },
  cardTitleRoot: {
    marginBottom: 24,
    '& .Cmt-badge-avatar': {
      padding: 5,
      borderRadius: '50%',
    },
    '& .Cmt-badge': {
      padding: 0,
      backgroundColor: 'transparent',
      marginBottom: -36,
      marginLeft: -15,
    },
    '& .Cmt-user-info': {
      marginTop: 15,
      '& .title': {
        fontSize: 16,
        fontWeight: theme.typography.fontWeightBold,
      },
    },
  },
  formControl: {
    margin: theme.spacing(2),
  },
  selectEmpty: {
    marginTop: theme.spacing(4),
  },
  cirProRoot: {
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 1,
  },
  cirProGrey: {
    color: theme.palette.grey[200],
  },
  avatarRoot: {
    border: `solid 2px ${theme.palette.common.white}`,
    marginTop: -125,
    marginBottom: 13,
  },
}))

const UserDetail = () => {
  const classes = useStyles()

  const [userDetails, setUseDetail] = useState({
    conected: false,
    badge: '/images/icons/badge-copy.png',
    name: '',
    device: '',
    job_title: '',
    stats: {
      Device: '',
      Dispositivo: '',
      Versão: '',
      Status: 'DESCONECTADO',
      'Conectado em': '',
    },
  })
  const [conected, setConected] = useState(false)
  const [readQrCode, setReadQrCode] = useState(false)
  const [reiniciarSessao, setReiniciarSessao] = useState('static')
  const [valueReiniciarSessao, setValueReiniciarSessao] = useState(100)
  const [qrCode, setQrcode] = useState(
    'https://www.qrcodefacil.com/static/media/qrcode-preview.00309a9d.svg',
  )
  const [isSession, setIsSession] = useState(false)
  const [setores, setSetores] = useState()
  const [setor, setSetor] = useState({})
  const [loader, setLoader] = useState(true)
  const [isExclusiveSector, setIsExclusiveSector] = useState(false)
  const [imagens, setImagens] = useState()
  const [imagemSelected, setImagemSelected] = useState()

  const { authUser } = useSelector(({ auth }) => auth)

  useEffect(() => {
    axios
      .get('imagens?type=whatsapp')
      .then((success) => {
        let images = success.data
        if (images?.length === 1) {
          setImagemSelected(images[0]._id)
          setImagens(images)
          return
        }
        const newImageName = 'Novo (Com Botões)'
        // const userHasAccessToNewImage =
        //   authUser.featureFlags?.length > 0 &&
        //   (authUser.featureFlags?.includes('zaphook') ||
        //     authUser.featureFlags?.includes('zaphooks'))
        const userHasAccessToNewImage = false
        if (!userHasAccessToNewImage) {
          images = images.filter((item) => item.nome !== newImageName)
        }
        if (authUser._id !== '620ae81a5f8391b1b3b25fe2') {
          images = images.filter((image) => image.nome !== 'Pre Production')
        }
        images.length > 0 && setImagemSelected(images[0]._id)
        setImagens(images)
      })
      .catch((err) => console.log(err))
  }, [])

  useEffect(() => {
    if (!setor?._id) return
    const socket = socketIOClient(URL_SOCKET, {
      query: {
        id: setor._id,
        origin: 'qrCode',
      },
      transports: ['websocket'],
    })

    if (setor._id) {
      socket.on('connect', () => {
        console.log(`[CLIENT_SOCKET_IO] Connected:`, socket.connected)
      })
    }

    socket.on('whastappSession', (data) => {
      if (data.statusChange == 'delete') {
        setConected(false)
        // setReadQrCode(false);
        setQrcode(
          'https://www.qrcodefacil.com/static/media/qrcode-preview.00309a9d.svg',
        )
        setUseDetail({
          conected: false,
          badge: '/images/icons/badge-copy.png',
          name: '',
          device: '',
          job_title: '',
          stats: {
            Device: '',
            Dispositivo: '',
            Versão: '',
            Status: 'DESCONECTADO',
            Imagem: '',
          },
        })
        setReadQrCode(false)
      } else if (data.isConected) {
        var newUserDetails = { ...userDetails }
        newUserDetails.stats['Conectado em'] = new Date(
          data?.createdAt,
        ).toLocaleString('pt-BR')
        newUserDetails.conected = data.isConected
        newUserDetails.stats.Status = 'ATIVO'
        newUserDetails.stats.Device = data.host.device.device
        newUserDetails.name = data.host ? data.host.device.pushName : null
        newUserDetails.stats.Imagem = data.image_docker.includes('zaphook')
          ? 'Novo'
          : 'Padrão'

        setIsExclusiveSector(data.isExclusiveSector)
        setConected(true)
        setUseDetail(newUserDetails)
        setReadQrCode(false)
      } else {
        if (data.qrCode) {
          setQrcode(data.qrCode)
        } else {
          setConected(false)
          // setReadQrCode(false);
          setQrcode(
            'https://www.qrcodefacil.com/static/media/qrcode-preview.00309a9d.svg',
          )
          setUseDetail({
            conected: false,
            badge: '/images/icons/badge-copy.png',
            name: '',
            device: '',
            job_title: '',
            stats: {
              Device: '',
              Dispositivo: '',
              Status: 'DESCONECTADO',
            },
          })
        }
      }
    })
    return () => socket.disconnect()
  }, [setor])

  useEffect(() => {
    const token = localStorage.getItem('token')
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token

    axios
      .get('atendimento/departamento')
      .then((succes) => {
        setSetores(succes.data)
        setLoader(false)
      })
      .catch((error) => error)
  }, [])

  const handleChangeQrCode = () => {
    setReadQrCode(true)
    const token = localStorage.getItem('token')
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
    axios
      .post('whatsapp/init', {
        sessionId: setor._id,
        imagemId: imagemSelected,
      })
      .then((succes) => {
        if (succes.data.error) {
          setReadQrCode(false)
          setIsSession(true)
          NotificationManager.error(succes.data.message)
        } else {
          NotificationManager.success(succes.data.message)
        }
      })
      .catch((error) => {
        setReadQrCode(false)
        NotificationManager.error(error.response.data.message)
      })
  }

  const handleChangeStopSessao = () => {
    setReiniciarSessao('indeterminate')
    setValueReiniciarSessao(80)

    axios
      .post('whatsapp/stop', {
        sessionId: setor._id,
      })
      .then((succes) => {
        setReiniciarSessao('static')
        setValueReiniciarSessao(100)
        NotificationManager.success(succes.data.message)
      })
      .catch((error) => {
        setReiniciarSessao('static')
        setValueReiniciarSessao(100)
        NotificationManager.error('Falha ao pausar sessão!')
      })
  }

  const handleChangeExclusiveSector = () => {
    setIsExclusiveSector(!isExclusiveSector)
    axios
      .post('whatsapp/setExclusiveSector', {
        departamento: setor._id,
        isExclusiveSector: !isExclusiveSector,
      })
      .then((succes) => {
        NotificationManager.success(succes.data.message)
      })
      .catch((error) => {
        NotificationManager.error('Falha ao reinciar sessão!')
      })
  }

  const handleChangeReiniciarSessao = () => {
    setReiniciarSessao('indeterminate')
    setValueReiniciarSessao(80)

    axios
      .post('whatsapp/reiniciar', {
        sessionId: setor._id,
      })
      .then((succes) => {
        setReiniciarSessao('static')
        setValueReiniciarSessao(100)
        NotificationManager.success(succes.data.message)
      })
      .catch((error) => {
        setReiniciarSessao('static')
        setValueReiniciarSessao(100)
        NotificationManager.error('Falha ao reinciar sessão!')
      })
  }

  const handleChangeExcluirSessao = () => {
    setReiniciarSessao('indeterminate')
    setValueReiniciarSessao(80)
    setQrcode(
      'https://www.qrcodefacil.com/static/media/qrcode-preview.00309a9d.svg',
    )

    axios
      .post('whatsapp/excluirSesao', {
        sessionId: setor._id,
      })
      .then((succes) => {
        var newUserDetails = { ...userDetails }

        newUserDetails.conected = false
        newUserDetails.stats.Status = 'DESCONECTADO'
        newUserDetails.name = '-'
        newUserDetails.job_title = '-'
        setUseDetail(newUserDetails)
        setReadQrCode(false)
        setConected(false)
        setReiniciarSessao('static')
        setValueReiniciarSessao(100)
        NotificationManager.success(succes.data.message)
      })
      .catch((error) => {
        setReadQrCode(false)
        setReiniciarSessao('static')
        setValueReiniciarSessao(100)
        NotificationManager.error('Erro ao deletar sessão!')
      })
  }

  const handleImagem = (e) => {
    setImagemSelected(e.target.value)
  }

  const sincronizarMensagens = () => {}

  return (
    <React.Fragment>
      {!loader && (
        <FormControl className={classes.formControl} fullWidth>
          <InputLabel id="demo-simple-select-label">
            Selecione um Setor
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={setor.nome}
            onChange={(e) => {
              setConected(false)
              setUseDetail({
                conected: false,
                badge: '/images/icons/badge-copy.png',
                name: '',
                job_title: '',
                stats: {
                  Status: 'DESCONECTADO',
                },
              })
              setSetor({ _id: e.target.value, nome: e.target.key })
            }}
          >
            {setores.map((row, key) => (
              <MenuItem key={row.nome} value={row._id}>
                {row.nome}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {conected ? (
        <React.Fragment>
          <Box className={classes.userDetailRoot}>
            <CircularProgress
              className={clsx(classes.cirProRoot, classes.cirProGrey)}
              variant="static"
              color="secondary"
              size={112}
              value={100}
              thickness={2}
            />
            <CircularProgress
              className={classes.cirProRoot}
              variant={reiniciarSessao}
              color="secondary"
              size={112}
              value={valueReiniciarSessao}
              thickness={1}
            />
          </Box>
          <Box className={classes.cardTitleRoot}>
            <CmtObjectSummary
              avatar={
                <CmtAvatar
                  className={classes.avatarRoot}
                  size={90}
                  src={authUser.photo}
                  alt={authUser.nome}
                />
              }
              title={userDetails.name}
              titleProps={{
                style: { fontSize: 16, fontWeight: 'bold', marginBottom: 5 },
              }}
              subTitle={userDetails.job_title}
              align="vertical"
            />
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {readQrCode && (
            <Box className={classes.cardTitleRoot}>
              <CmtObjectSummary
                avatar={
                  <div>
                    <img src={`${qrCode}`} width="50%" />
                    <LinearProgress color="primary" variant="indeterminate" />
                  </div>
                }
                title="Qr Code"
                titleProps={{
                  style: { fontSize: 16, fontWeight: 'bold', marginBottom: 5 },
                }}
                align="vertical"
              />
            </Box>
          )}
        </React.Fragment>
      )}
      {loader ? (
        <LinearProgress color="secondary" />
      ) : setores.length === 0 ? (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() =>
            sendParentEvent({ redirect: '/dashboard/departments' })
          }
        >
          <CmtList
            data={notifications}
            renderRow={(item, index) => <ItemCell item={item} key={index} />}
          />
        </div>
      ) : (
        <React.Fragment>
          <Box>
            {setor._id && (
              <React.Fragment>
                <UserInfo
                  userDetails={userDetails}
                  imagens={imagens}
                  handleImagem={handleImagem}
                  imagemSelected={imagemSelected}
                  userFunctions={{
                    readQrCode,
                    isSession,
                    handleChangeQrCode,
                    handleChangeReiniciarSessao,
                    handleChangeExcluirSessao,
                    handleChangeStopSessao,
                    sincronizarMensagens,
                    isExclusiveSector,
                    handleChangeExclusiveSector,
                  }}
                />
              </React.Fragment>
            )}
          </Box>
        </React.Fragment>
      )}
      <NotificationContainer />
    </React.Fragment>
  )
}

export default UserDetail
