import React, { useState } from 'react'
import { BsClipboard, BsCheck } from 'react-icons/bs'

export function VariableRow({ value, text }) {
  const [copied, setCopied] = useState(false)

  const handleCopy = () => {
    setCopied(true)
    navigator.clipboard.writeText(value)
    setTimeout(() => {
      setCopied(false)
    }, 2000)
  }
  return (
    <>
      <p
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '0.5rem',
          cursor: 'pointer',
          marginTop: '0.5rem'
        }}
        onClick={handleCopy}
      >
        <div style={{ width: '20px', height: '20px' }}>
          {copied ? (
            <BsCheck
              size={22}
              style={{
                marginTop: '2px',
                marginRight: '0.5rem',
                color: 'green'
              }}
            />
          ) : (
            <BsClipboard
              size={16}
              style={{ marginTop: '2px', marginRight: '0.5rem' }}
            />
          )}
        </div>
        <strong>{value} </strong> {text}
      </p>
    </>
  )
}
