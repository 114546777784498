// TIPO DAS ETAPAS DO BOT
export const BOT_ETAPA_INICIO = 'inicio'
export const BOT_ETAPA_LISTAR_DEPARTAMENTO = 'listar_departamentos'
export const BOT_ETAPA_CHAMAR_ATENDENTE = 'chamar_atendente'
export const BOT_ETAPA_API = 'api'
export const BOT_ETAPA_FIM = 'fim'
export const BOT_ETAPA_MENU = 'menu'
export const BOT_ETAPA_PERGUNTA = 'pergunta'
export const BOT_ETAPA_MENSAGEM_SIMPLES = 'mensagem_simples'
export const BOT_ETAPA_DELAY = 'delay'
